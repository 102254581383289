import { styled } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const Wrapper = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
`;

const LabelEl = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: ${({ $required, $invalid }) =>
    $required ? ($invalid ? "#EF233C" : "#F77F00") : "#003060"};
`;

const InputEl = styled.input`
  padding: 10px 20px;
  background: transparent;
  border: none;
  color: #16161a;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  border: ${({ $invalid }) =>
    $invalid ? "2px solid #EF233C" : "1px solid transparent"};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const RequiredIcon = styled.div`
  font-size: 5px;
  padding-left: 4px;
`;

const Content = styled.div`
  padding: 10px 20px;
  color: #16161a;
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

const Calendar = ({
  title,
  onChangeFun,
  selectDate,
  placeholder,
  minDate,
  required,
  invalid,
}) => {
  const dateInputRef = useRef(null);

  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  const handleBlur = (e) => {
    setFocused(false);
  };

  const dateFormat = (value) => {
    if (value) {
      const date = moment(value, "YYYY-MM-DD").toDate();
      return date;
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <InputWrapper
        onClick={onClick}
        ref={ref}
        $required={required}
        $invalid={invalid && !focused && !selectDate}
      >
        <Content placeholder={placeholder}> {value}</Content>
        <IconWrapper>
          <FontAwesomeIcon icon={faCalendar} />
        </IconWrapper>
      </InputWrapper>
    );
  });

  return (
    <Wrapper>
      <Container>
        <LabelEl
          $required={required}
          $invalid={invalid && !focused && !selectDate}
        >
          {title}
          {required && (
            <RequiredIcon>
              <FontAwesomeIcon icon={faStarOfLife} />
            </RequiredIcon>
          )}
        </LabelEl>

        <DatePicker
          selected={dateFormat(selectDate)}
          onChange={(date) => onChangeFun(date)}
          dateFormat="yyyy/MM/dd"
          minDate={minDate && new Date()}
          customInput={<CustomInput ref={dateInputRef} />}
        />
      </Container>
    </Wrapper>
  );
};

export default Calendar;
