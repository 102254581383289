import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const Title = styled.div`
  font-size: 2vw;
  padding-bottom: 50px;
`;

const LinkButton = styled.button`
  border: 1px solid #1e2022;
  background-color: #1e2022;
  color: #fcfcfc;
  padding: 10px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  font-size: 100px;
  padding: 20px;
`;

const NoAuthPage = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <IconWrapper>
          <FontAwesomeIcon icon={faLock} />
        </IconWrapper>
        <Title>SORRY - You don't have permission to access！</Title>
        <LinkButton
          onClick={() =>
            navigate(`${process.env.REACT_APP_PATH}/dashboard/orderRecord`)
          }
        >
          Back To Home
        </LinkButton>
      </Container>
    </Wrapper>
  );
};

export default NoAuthPage;
