import { styled, css } from "styled-components";
import { useState, useEffect } from "react";
import { getAuthToken, clearAuthToken, clearAuthFun } from "../../utils";
import { useLogoutMutation } from "../../services/loginApi";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Connecting from "./Connecting";

const Wrapper = styled.div`
  position: fixed;
  width: calc(100% - 200px);
  height: 60px;
  top: 0;
  right: 0;
  background: white;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  width: 90%;

  span {
    color: #ccc;
  }
`;

const UserWrapper = styled.div`
  padding-right: 15px;
`;

const LogoutWrapper = styled.div`
  /* padding-left: 20px; */
  cursor: pointer;
`;

const PrintWrapper = styled.div`
  /* padding-left: 20px; */
  cursor: pointer;
  color: ${({ $status }) => ($status ? "#ccc" : " #16161a")};
`;

const FunctionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
`;

const Header = ({ reconnect, connectionStatus, connectIsLoading }) => {
  const navigate = useNavigate();
  const [_logout, { data, isError, error, isSuccess }] = useLogoutMutation();

  const handleLogout = async () => {
    await _logout();
  };

  useEffect(() => {
    if (isSuccess) {
      clearAuthToken();
      clearAuthFun();
      navigate(`${process.env.REACT_APP_PATH}`);
    }
  }, [data, isError]);

  const handleReconnect = () => {
    if (connectionStatus !== "connected") {
      reconnect();
    } else {
      return;
    }
  };

  return (
    <Wrapper>
      {connectIsLoading && <Connecting />}
      <Container>
        <UserWrapper>{`Hello ${getAuthToken()?.user}`}</UserWrapper>
        <span> | </span>
        <FunctionWrapper>
          <PrintWrapper
            onClick={handleReconnect}
            $status={connectionStatus !== "connected"}
          >
            <FontAwesomeIcon icon={faPrint} />
          </PrintWrapper>
          <LogoutWrapper onClick={handleLogout}>
            <FontAwesomeIcon icon={faRightFromBracket} />
            {/* <Logout onClick={handleLogout}>Logout</Logout> */}
          </LogoutWrapper>
        </FunctionWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
