import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken, setAuthFun } from "../utils";

const initialState = {
  user: null,
  token: null,
  userAuths: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUser: (state, action) => {
      setAuthToken(action.payload.token, action.payload.user);
    },
    setAuthFunctions: (state, action) => {
      state.userAuths = action.payload.UserAuths;
      setAuthFun(JSON.stringify(action.payload.UserAuths));
    },
  },
});

// Export actions
export const { setUser, setAuthFunctions } = loginSlice.actions;

// Export select
export const selectAuth = (state) => state.login;

// Export reducer
export default loginSlice.reducer;
