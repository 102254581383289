import styled, { keyframes, css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  backdrop-filter: blur(2px);
  z-index: 98;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

const Animation = styled.div`
  animation: ${rotate} 2s linear infinite;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50vh;
`;

const Loading = () => {
  return (
    <Wrapper>
      <Animation>
        <FontAwesomeIcon icon={faSpinner} />
      </Animation>
    </Wrapper>
  );
};

export default Loading;
