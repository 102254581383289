import { styled } from "styled-components";

const Wrapper = styled.div`
  border: 1px solid red;
`;

const DashboardPage = () => {
  return <Wrapper>DashboardPage</Wrapper>;
};

export default DashboardPage;
