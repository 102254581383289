import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getAuthToken, setAuthToken, clearAuthToken } from "../../utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}`,
  prepareHeaders: (headers) => {
    const token = getAuthToken().token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithToken = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error?.status === 401 || result.error.data?.ResultCode === 401) {
      const refreshResult = await baseQuery(
        "Auth/RefreshToken",
        api,
        extraOptions
      );

      if (refreshResult.data) {
        setAuthToken(refreshResult.data.AccessToken, getAuthToken().user);
      } else {
        MySwal.fire({
          title: "帳號驗證失敗，請重新登入",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#16161a",
          allowOutsideClick: false,
        }).then((result) => {
          console.log("401 token失效需重新登入");
          if (result.isConfirmed) {
            clearAuthToken();
            window.location.replace(
              `${window.location.origin}${process.env.REACT_APP_PATH}`
            );
          }
        });
      }
    } else {
      console.log(result.error);
    }
  }

  return result;
};
export default baseQueryWithToken;
