import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px 10px;

  ${RWD_SM(css`
    grid-template-columns: repeat(2, 1fr);
  `)};
`;

const LableEl = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px;
  background: #fafafa;
`;

const ColorText = styled.div`
  text-align: left;
  width: 100%;
  text-align: center;
`;

const CheckboxEl = styled.input.attrs({ type: "checkbox" })`
  accent-color: #16161a;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const MultipleCheckbox = ({ colorListData, setColorListData }) => {
  const colourData = useSelector(selectDropDown).List?.Colour[0]?.Options;

  console.log(colorListData);

  const handleChange = (e, id) => {
    if (id) {
      setColorListData(
        colorListData?.map((data) => {
          if (data?.ID == id) {
            return {
              ID: data?.ID,
              ColourID: data.ColourID,
              Enabled: e.target.checked ? 1 : 0,
            };
          }

          return data;
        })
      );
    } else {
      const exists = colorListData?.findIndex(
        (data) => data.ColourID == e.target.value
      );

      if (exists !== -1) {
        setColorListData(
          colorListData.filter((data) => data.ColourID != e.target.value)
        );
        console.log(colorListData);
      } else {
        setColorListData((colorListData) => [
          ...colorListData,
          {
            ColourID: Number(e.target.value),
            // ColourName: e.target.name,
            // ColourRemark: "",
            // Enabled: 1,
          },
        ]);
      }
    }

    console.log("id" + id);

    // if (exists !== -1) {
    //   setColorListData(
    //     colorListData?.map((data) => {
    //       if (data?.ID == id) {
    //         return {
    //           ID: data?.ID,
    //           ColourID: data.ColourID,
    //           Enabled: e.target.checked ? 1 : 0,
    //         };
    //       }

    //       return data;
    //     })
    //   );
    // } else {
    // }
  };

  return (
    <Wrapper>
      <Container>
        <Title>顏色</Title>
        <CheckboxWrapper>
          {colourData?.map((data) => (
            <LableEl key={data.Value}>
              <CheckboxEl
                name={data.Text}
                onChange={(e) =>
                  handleChange(
                    e,
                    colorListData?.filter(
                      (item) => item.ColourID === data.Value
                    )[0]?.ID
                  )
                }
                value={data.Value || ""}
                checked={
                  colorListData?.filter(
                    (item) => item.ColourID === data.Value
                  )[0]?.Enabled
                }
              />

              <ColorText>{data.Text}</ColorText>
            </LableEl>
          ))}
        </CheckboxWrapper>
      </Container>
    </Wrapper>
  );
};

export default MultipleCheckbox;
