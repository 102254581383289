import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeaderTr = styled.tr``;

const TableHeader = styled.thead`
  /* display: flex; */
  /* width: 100vw; */
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableHeaderItem = styled.th`
  padding: 20px 15px;

  white-space: nowrap;
  /* color: #d6d6d6; */
  background: #fafafa;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const TableContainer = styled.tbody`
  /* padding-top: 20px; */
  /* overflow-x: scroll; */
  /* width: 100vw; */
`;

const ParamContainer = styled.tr`
  border-bottom: 1px solid #f3f4f6;
  /* display: flex; */
  /* display: flex; */

  cursor: pointer;

  &:hover {
    background-color: #fcfcfc;
  }
`;

const ParamItem = styled.td`
  /* padding: 0 20px; */
  padding: 20px 15px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  white-space: nowrap;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const CountWrapper = styled.div`
  padding-top: 40px;
`;

const LogoTab = ({ setIsOpen, setModalData, paramData, setModalType }) => {
  const [logoData, setLogoData] = useState([]);

  useEffect(() => {
    setLogoData(paramData);
  }, [paramData]);

  const header = [
    {
      id: 1,
      name: "編號",
      value: "ID",
      width: "20",
    },
    {
      id: 2,
      name: "名稱",
      value: "Name",
      width: "20",
    },
    {
      id: 3,
      name: "價格",
      value: "Cost",
      width: "20",
    },
    {
      id: 4,
      name: "更新時間",
      value: "UpdateDate",
      width: "20",
    },
    {
      id: 5,
      name: "操作",
      value: "Action",
      width: "20",
    },
  ];

  const handleEdit = (id) => {
    setIsOpen(true);
    setModalType("edit");
    setModalData(logoData.filter((data) => data.ID === id));
  };

  return (
    <Wrapper>
      <Container>
        <TableWrapper>
          <TableHeader>
            <TableHeaderTr>
              {header.map((item) => (
                <TableHeaderItem
                  key={item.id}
                  // style={{
                  //   width: `${item.width}%`,
                  // }}
                >
                  {item.name}
                </TableHeaderItem>
              ))}
            </TableHeaderTr>
          </TableHeader>
          <TableContainer>
            {logoData.map((data, index) => (
              <ParamContainer key={index} onClick={() => handleEdit(data.ID)}>
                {header.map((item) => (
                  <ParamItem
                    key={item.id}
                    // style={{
                    //   width: `${item.width}%`,
                    // }}
                  >
                    {(() => {
                      switch (item.value) {
                        case "ID":
                          return index + 1;
                        case "Action":
                          return <FontAwesomeIcon icon={faPencil} />;

                        case "Cost":
                          return data.Cost ?? 0;

                        default:
                          return data[item.value];
                      }
                    })()}
                  </ParamItem>
                ))}
              </ParamContainer>
            ))}
          </TableContainer>
        </TableWrapper>
      </Container>
      <CountWrapper>{`共 ${logoData.length} 筆`}</CountWrapper>
    </Wrapper>
  );
};

export default LogoTab;
