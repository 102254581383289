import { styled } from "styled-components";

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LableEl = styled.label`
  text-align: left;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  padding-bottom: 20px;
  color: #003060;
`;

const TextareaEl = styled.textarea`
  padding: 20px;
  height: 100px;
  background: #fafafa;
  border: none;
`;

const Textarea = ({ title, onChangeFun, defaultValue, value }) => {
  return (
    <Wrapper>
      <Container>
        <LableEl>{title}</LableEl>
        <TextareaEl
          onChange={(e) => onChangeFun(e.target.value)}
          // defaultValue={defaultValue}
          value={value || ""}
        />
      </Container>
    </Wrapper>
  );
};

export default Textarea;
