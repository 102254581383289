import { styled, css } from "styled-components";
import { useEffect, useState } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Input from "../../global/Input";
import Textarea from "../../global/Textarea";
import Checkbox from "../../global/Checkbox";
import Button from "../../global/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import {
  useUpdateParamMutation,
  useCreateParamMutation,
} from "../../services/systemApi";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  position: relative;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.302vw, 20px), 25px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;
`;

const CloseIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #16161a;
  color: #fcfcfc;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px 5vw;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 30px;
`;
const SystemModalLayout = ({
  setIsOpen,
  modalData,
  activeTab,
  reFetch,
  modalType,
  setModalType,
}) => {
  // params
  // 0 穿線法
  // 1 孔丁
  // 2 急件
  // 3 Logo

  const MySwal = withReactContent(Swal);

  const [
    _update,
    {
      data: updateData,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      error: updateError,
    },
  ] = useUpdateParamMutation();

  const [
    _create,
    {
      data: createData,
      isError: createIsError,
      isSuccess: createIsSuccess,
      error: createError,
    },
  ] = useCreateParamMutation();

  useEffect(() => {
    if (updateIsSuccess) {
      MySwal.fire({
        title: "更新成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
          const data = {
            Type: type,
          };
          reFetch(data);
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (updateIsError) {
      MySwal.fire({
        title: "更新失敗",
        text: updateError?.data?.Message,
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [updateData, updateIsError]);

  useEffect(() => {
    if (createIsSuccess) {
      MySwal.fire({
        title: "建立成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
          const data = {
            Type: type,
          };
          reFetch(data);
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (createIsError) {
      MySwal.fire({
        title: "建立失敗",
        text: createError?.data?.Message,
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [createData, createIsError]);

  const handleCloseFun = () => {
    setIsOpen(false);
    handleClear();
  };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [cost, setCost] = useState("");
  const [remark, setRemark] = useState("");
  const [enabled, setEnabled] = useState(true);

  const isValid = name !== "" && cost !== "";

  useEffect(() => {
    if (modalData.length > 0 && modalType !== "create") {
      setName(modalData[0]?.Name);
      setCost(modalData[0]?.Cost);
      setRemark(modalData[0]?.Remark);
      setType(modalData[0]?.Type);
      setId(modalData[0]?.ID);
    }
  }, [modalData]);

  useEffect(() => {
    setType(() => {
      switch (activeTab) {
        case 2:
          return 0;
        case 3:
          return 1;
        case 4:
          return 2;
        default:
          break;
      }
    });
  }, [activeTab]);

  const handleUpdate = () => {
    const data = {
      ID: id,
      Type: type,
      Name: name,
      Cost: cost,
      Remark: remark,
      Enabled: enabled ? 1 : 0,
    };

    _update(data);
  };

  const handleCreate = () => {
    const data = {
      ListParam: [
        {
          Type: type,
          Name: name,
          Cost: cost,
          Remark: remark,
        },
      ],
    };

    _create(data);
  };

  const handleClear = () => {
    console.log(modalType);
    setId("");
    setType("");
    setName("");
    setCost("");
    setRemark("");
    setEnabled(true);
    // setModalType("");
  };
  console.log(activeTab);

  return (
    <Wrapper>
      <Container>
        <CloseIcon onClick={handleCloseFun}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseIcon>
        <Title>
          {(() => {
            switch (activeTab) {
              case 2:
                return "穿線法";
              case 3:
                return "孔丁";
              case 4:
                return "案件";
              case 5:
                return "Logo";
            }
          })()}
          {modalType === "create" ? "建立" : "維護"}
        </Title>
        <FormWrapper>
          <Input title={"名稱"} onChangeFun={setName} value={name} />
          <Input title={"價格"} onChangeFun={setCost} value={cost} />
          {modalType !== "create" && (
            <Checkbox title={"啟用"} onChangeFun={setEnabled} value={enabled} />
          )}
        </FormWrapper>
        <Textarea title={"備註"} onChangeFun={setRemark} value={remark} />
        <ButtonWrapper>
          {modalType === "create" && (
            <Button text={"清除資料"} onClickFun={handleClear} />
          )}
          {modalType === "create" ? (
            <Button
              text={"建立"}
              onClickFun={handleCreate}
              disabled={!isValid}
            />
          ) : (
            <Button
              text={"更新"}
              onClickFun={handleUpdate}
              disabled={!isValid}
            />
          )}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default SystemModalLayout;
