import { styled, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Button from "../global/Button";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 50px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 50px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const SettingFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const SettingForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  `)};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputEl = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #1e2022;
`;

const TextareaEl = styled.textarea`
  border: 1px solid #1e2022;
  padding: 10px;
`;

const LabelEl = styled.label`
  width: 50%;
  text-align: left;

  ${RWD_SM(css`
    width: 75%;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  width: 100px;
  margin-left: auto;
`;

const ThermalPrinter = () => {
  const { ip, port, connect, connection } = useOutletContext();
  const MySwal = withReactContent(Swal);

  const [printerIPAddress, setPrinterIPAddress] = useState();
  const [printerPort, setPrinterPort] = useState("");
  const [textToPrint, setTextToPrint] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");

  const ePosDevice = useRef();
  const printer = useRef();

  const STATUS_CONNECTED = "connected";

  // 外網
  // 125.228.238.87:777
  // 內網
  // 192.168.50.111:9100

  const handleConnect = () => {
    connect[0]();
  };

  // const connect = () => {
  //   setConnectionStatus("Connecting ...");

  //   if (!printerIPAddress) {
  //     setConnectionStatus("Type the printer IP address");
  //     return;
  //   }
  //   if (!printerPort) {
  //     setConnectionStatus("Type the printer port");
  //     return;
  //   }

  //   setConnectionStatus("Connecting ...");

  //   let ePosDev = new window.epson.ePOSDevice();
  //   ePosDevice.current = ePosDev;

  //   console.log(ePosDev);

  //   ePosDev.connect(printerIPAddress, printerPort, (data) => {
  //     console.log(data);
  //     if (data === "OK" || data === "SSL_CONNECT_OK") {
  //       ePosDev.createDevice(
  //         "local_printer",
  //         ePosDev.DEVICE_TYPE_PRINTER,
  //         { crypto: true, buffer: false },
  //         (devobj, retcode) => {
  //           if (retcode === "OK") {
  //             printer.current = devobj;
  //             setConnectionStatus(STATUS_CONNECTED);
  //           } else {
  //             throw retcode;
  //           }
  //         }
  //       );
  //     } else {
  //       throw data;
  //     }
  //   });
  // };

  const print = (text) => {
    // let prn = printer.current;
    // if (!prn) {
    //   alert("Not connected to printer");
    //   return;
    // }

    // prn.addTextLang("zh-tw");
    // prn.addTextSize(2, 2);
    // prn.addTextAlign(prn.ALIGN_CENTER);
    // prn.addText("Stringing System\n");
    // prn.addFeedLine(1);
    // prn.addPageBegin();
    // prn.addTextAlign(prn.ALIGN_LEFT);
    // prn.addTextSize(1, 1);
    // prn.addPageArea(0, 0, 575, 200);
    // prn.addText("客戶姓名:\t使用者\n");
    // prn.addText("電話:\t0909123456\n");
    // prn.addText("線種:\tBG80\n");
    // prn.addText("顏色:\t白色\n");
    // prn.addText("磅數:\t25/27\n");
    // prn.addText("穿線法:\t25/27\n");
    // prn.addText("交拍日:\t2023/09/19\n");
    // prn.addText("取拍日:\t2023/09/25\n");
    // prn.addText("經手人:\tAdmin\n");
    // prn.addText("付款狀態:\t尚未付款\n");
    // prn.addText("加購項目:\t一般急件 / 單線孔\n");
    // prn.addText("其他:\t\n");
    // // prn.addPageLine(0, 100, 100, 50, prn.LINE_THICK);
    // prn.addPageEnd();
    // prn.addText("金額:\t500\n");
    // prn.addText(text);
    // prn.addFeedLine(1);
    // prn.addCut(prn.CUT_FEED);

    // prn.send();

    var req = `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header>
    <parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    <devid>local_printer</devid>
    <timeout>10000</timeout>
    </parameter>
    </s:Header>
    <s:Body>
    <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    <page>
    <area x="0" y="0" width="345" height="990"/>
    <text lang="zh-tw"/>
    <text smooth="true"/>
    <direction dir="top_to_bottom"/>
    <text font="font_a"/>
    <text align="center"/>
    <feed/>
    <text align="left"/>
    <text width="1" height="1"/>
    <position x="1" y="30"/>
    <text>電話</text>
    <position x="200" y="30"/>
    <text>undefined</text>
    <position x="1" y="80"/>
    <text>姓名</text>
    <position x="200" y="80"/>
    <text>undefined</text>
    <position x="1" y="130"/>
    <text>線種(顏色)</text>
    <position x="200" y="130"/>
    <text>undefined (undefined)</text>
    <position x="1" y="180"/>
    <text>磅數(穿線法)</text>
    <position x="200" y="180"/>
    <text>undefined(undefined)</text>
    <position x="1" y="230"/>
    <text>加購</text>
    <position x="200" y="230"/>
    <text>undefined</text>
    <position x="1" y="280"/>
    <text>金額(已)</text>
    <position x="200" y="280"/>
    <text>undefined </text>
    <position x="495" y="30"/>
    <text>交拍日</text>
    <position x="695" y="30"/>
    <text>undefined</text>
    <position x="495" y="80"/>
    <text>收拍日</text>
    <position x="695" y="80"/>
    <text>undefined</text>
    <position x="495" y="130"/>
    <text>經手人</text>
    <position x="695" y="130"/>
    <text>undefined</text>
    <position x="495" y="180"/>
    <text>訂單編號</text>
    <position x="695" y="180"/>
    <text>undefined_undefined</text>
    <position x="495" y="230"/>
    <text>其他</text>
    <position x="695" y="230"/>
    <text></text>
    <feed/>
    </page>
    <cut type="feed"/>
    <page>
    <area x="0" y="0" width="345" height="990"/>
    <text lang="zh-tw"/>
    <text smooth="true"/>
    <direction dir="top_to_bottom"/>
    <text font="font_a"/>
    <text align="center"/>
    <feed/>
    <text align="left"/>
    <text width="1" height="1"/>
    <position x="1" y="30"/>
    <text>電話</text>
    <position x="200" y="30"/>
    <text>undefined</text>
    <position x="1" y="80"/>
    <text>姓名</text>
    <position x="200" y="80"/>
    <text>undefined</text>
    <position x="1" y="130"/>
    <text>線種(顏色)</text>
    <position x="200" y="130"/>
    <text>undefined (undefined)</text>
    <position x="1" y="180"/>
    <text>磅數(穿線法)</text>
    <position x="200" y="180"/>
    <text>undefined(undefined)</text>
    <position x="1" y="230"/>
    <text>加購</text>
    <position x="200" y="230"/>
    <text>undefined</text>
    <position x="1" y="280"/>
    <text>金額(已)</text>
    <position x="200" y="280"/>
    <text>undefined </text>
    <position x="495" y="30"/>
    <text>交拍日</text>
    <position x="695" y="30"/>
    <text>undefined</text>
    <position x="495" y="80"/>
    <text>收拍日</text>
    <position x="695" y="80"/>
    <text>undefined</text>
    <position x="495" y="130"/>
    <text>經手人</text>
    <position x="695" y="130"/>
    <text>undefined</text>
    <position x="495" y="180"/>
    <text>訂單編號</text>
    <position x="695" y="180"/>
    <text>undefined_undefined</text>
    <position x="495" y="230"/>
    <text>其他</text>
    <position x="695" y="230"/>
    <text></text>
    <feed/>
    </page>
    <cut type="feed"/>
    </epos-print>
    </s:Body>
    </s:Envelope>
    `;

    var url = `https://${ip[0]}/cgi-bin/epos/service.cgi`;

    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml; charset=utf-8");
    xhr.setRequestHeader("If-Modified-Since", "Thu, 01 Jan 1970 00:00:00 GMT");
    xhr.setRequestHeader("SOAPAction", '""');
    xhr.onreadystatechange = function () {
      // Receive response document
      if (xhr.readyState == 4) {
        // Parse response document
        if (xhr.status == 200) {
          // alert(xhr.responseXML.getElementsByTagName('response')[0].getAttribute('success'));
        } else {
          alert("Network error occured.");
        }
      }
    };

    xhr.send(req);
  };

  const testPrint = (text) => {
    var url = `https://${ip[0]}/cgi-bin/epos/service.cgi`;

    var req =
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
      "<s:Header>" +
      '<parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">' +
      "<devid>local_printer</devid>" +
      "<timeout>10000</timeout>" +
      "<printjobid>ABC123</printjobid>" +
      "</parameter>" +
      "</s:Header>" +
      "<s:Body>" +
      '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">' +
      '<pulse drawer="drawer_1" time="pulse_100" />' +
      '<text lang="en" smooth="true">Printer Test&#10;</text>' +
      "<text>" +
      text +
      "</text>" +
      '<feed unit="24"/>' +
      "<cut/>" +
      "</epos-print>" +
      "</s:Body>" +
      "</s:Envelope>";

    // Send print document
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml; charset=utf-8");
    xhr.setRequestHeader("If-Modified-Since", "Thu, 01 Jan 1970 00:00:00 GMT");
    xhr.setRequestHeader("SOAPAction", '""');
    xhr.onreadystatechange = function () {
      // Receive response document
      if (xhr.readyState == 4) {
        // Parse response document
        if (xhr.status == 200) {
          // alert(xhr.responseXML.getElementsByTagName('response')[0].getAttribute('success'));
        } else {
          MySwal.fire({
            title: "連線失敗",
            text: "Network error occured.",
            icon: "error",
            confirmButtonText: "確認",
            confirmButtonColor: "#16161a",
            allowOutsideClick: false,
          });
        }
      }
    };

    console.log(req);

    xhr.send(req);
  };

  return (
    <Wrapper>
      <Container id="thermalPrinter">
        <TitleWrapper>
          <Title>連線設定</Title>
        </TitleWrapper>
        <SettingFormWrapper>
          <SettingForm>
            <InputWrapper>
              <LabelEl>Printer IP Address</LabelEl>
              <InputEl
                id="printerIPAddress"
                placeholder="Printer IP Address"
                value={ip[0]}
                onChange={(e) => ip[1](e.target.value)}
              />
            </InputWrapper>

            <InputWrapper>
              <LabelEl>Printer Port</LabelEl>
              <InputEl
                id="printerPort"
                placeholder="Printer Port"
                value={port[0]}
                onChange={(e) => port[1](e.target.value)}
              />
            </InputWrapper>
          </SettingForm>

          <ButtonWrapper>
            <Button text={"連線"} onClickFun={() => handleConnect()}>
              Connect
            </Button>
          </ButtonWrapper>

          <span className="status-label">{connectionStatus}</span>
          <hr />
          <TextareaEl
            id="textToPrint"
            rows="10"
            placeholder="Text to print"
            value={textToPrint}
            onChange={(e) => setTextToPrint(e.target.value)}
          />
          <ButtonWrapper>
            <Button
              disabled={connection[0] !== STATUS_CONNECTED}
              onClickFun={() => testPrint(textToPrint)}
              text={"測試列印"}
            ></Button>
          </ButtonWrapper>
        </SettingFormWrapper>

        {/* <button onClick={() => testPrint()}>TestPrint</button> */}
      </Container>
    </Wrapper>
  );
};

export default ThermalPrinter;
