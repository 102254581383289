import { styled } from "styled-components";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  width: 100%;
`;

const LableEl = styled.label`
  display: flex;
`;

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: #003060;
`;

const CheckboxEl = styled.input.attrs({ type: "checkbox" })`
  accent-color: #16161a;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const Checkbox = ({
  title,
  onChangeFun,
  value,
  id,
  disabled,
  defaultValue,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleOnChange = (e) => {
    setIsChecked(!isChecked);
    onChangeFun(!isChecked);
  };

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>

        <LableEl id={id}>
          <CheckboxEl
            // value={value}
            onChange={handleOnChange}
            checked={isChecked}
            id={id}
            disabled={disabled}
          />
        </LableEl>
      </Container>
    </Wrapper>
  );
};

export default Checkbox;
