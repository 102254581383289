import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

const ButtonEl = styled.button`
  padding: 10px;
  background-color: ${({ $status }) => ($status ? "#fff" : "#1e2022")};
  cursor: ${({ $status }) => ($status ? "default" : "pointer")};
  /* background: #16161a; */
  min-width: 100px;
  /* max-width: 150px; */
  width: 100%;
  border: ${({ $status }) =>
    $status ? "1px solid #ccc " : "1px solid #1e2022"};

  ${RWD_SM(css`
    padding: 8px 10px;
  `)};
`;

const Text = styled.div`
  font-size: clamp(14px, 0.729vw, 16px);
  transition: all 0.3s;
  color: ${({ $status }) => ($status ? "#ccc" : " #fcfcfc")};
`;

const Button = ({ text, onClickFun, disabled }) => {
  return (
    <ButtonEl onClick={onClickFun} disabled={disabled} $status={disabled}>
      <Text $status={disabled}>{text}</Text>
    </ButtonEl>
  );
};

export default Button;
