import { styled, css } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { selectDropDown } from "../../slices/systemSlice";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Input from "../../global/Input";
import Select from "../../global/Select";
import Calendar from "../../global/Calendar";
import Checkbox from "../../global/Checkbox";
import Textarea from "../../global/Textarea";
import CombineInput from "../../global/CombineInput";
import SearchInput from "../../global/SearchInput";
import InputSelect from "../../global/InputSelect";
import Button from "../../global/Button";
import RadioButton from "../../global/RadioButton";
import AddInput from "../../global/AddInput";

import {
  formatDate,
  mobileFormat,
  valueCheck,
  deliveryFormatDate,
} from "../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  height: 80%;

  max-width: 850px;
  position: relative;

  ${RWD_LG(css`
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const ScrollContainer = styled.div`
  overflow: auto;
  padding-right: 20px;

  ${RWD_SM(css`
    padding-right: 10px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;

  ${RWD_SM(css`
    font-size: clamp(18px, 4.65vw, 20px);
    padding-bottom: 15px;
  `)};
`;

const FormWrapper = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px 5vw;
`;

const TextareaWrapper = styled.div`
  padding-bottom: 20px;
`;

const TotalAmountWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
`;

const TotalAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

const TotalAmount = styled.div`
  font-size: clamp(23.5px, 2.604167vw, 50px);
  padding-left: 20px;
  font-weight: bold;
`;

const TotalAmountText = styled.div`
  /* font-size: clamp(23.5px, 1.041vw, 45px); */
`;

const CloseIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #16161a;
  color: #fcfcfc;
  cursor: pointer;
`;

const AddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: #fafafa;
`;

const AddContainer = styled.div`
  display: grid;
  width: 95%;

  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding-top: 20px;
`;

const AddTitle = styled.div`
  padding-bottom: 20px;
  display: flex;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: #003060;
`;

const ButtonWrapper = styled.div`
  display: grid;
  margin-left: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  width: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrderLayout = ({
  setIsOpen,
  stringData,
  orderList,
  setOrderList,
  editData,
  modalType,
  setModalType,
  tempData,
  clearData,
}) => {
  const MySwal = withReactContent(Swal);

  const scrollRef = useRef(null);

  const handleScroll = () => {
    scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [stringID, setStringID] = useState("");
  // 顏色  ColourID / ColourName
  const [colourName, setColourName] = useState("");
  const [colourID, setColourID] = useState("");
  const [colour, setColour] = useState("");
  const [selectableColor, setSelectableColor] = useState([]);
  // Lbs
  const [firstLbsValue, setFirstLbsValue] = useState("");
  const [secondLbsValue, setSecondLbsValue] = useState("");
  const [method, setMethod] = useState("");
  const [isNew, setIsNew] = useState(false); // 是否為新拍穿線
  const [deposit, setDeposit] = useState(0); // 訂金
  const [amountPayable, setAmountPayable] = useState(0); // 應付金額
  const [payStatus, setPayStatus] = useState(false);
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [adjudicator, setAdjudicator] = useState("");
  const [cost, setCost] = useState(0);
  const [remark, setRemark] = useState("");

  // 加購區
  // 孔丁 案件 Logo 其他  自訂
  const [grommet, setGrommet] = useState([]);
  const [urgent, setUrgent] = useState([
    { AddOnCost: 0, AddOnID: 1, AddOnName: "正常案件", AddOnType: 2 },
  ]);
  const [logo, setLogo] = useState([]);
  const [other, setOther] = useState([]);
  const [customize, setCustomize] = useState([]);

  // 線種價錢
  const [stringCost, setStringCost] = useState(0);

  // 表單驗證
  const [checkDataIsValid, setCheckDataIsValid] = useState([]);

  // 必填 姓名 電話 線種 磅數 交拍日 經手人
  const isValid =
    customerName !== "" &&
    customerID !== "" &&
    stringID !== "" &&
    firstLbsValue !== "" &&
    deliveryDate !== null &&
    deliveryDate !== "";

  const handleCloseFun = () => {
    setIsOpen(false);
    setModalType("create");
    handleClear();
  };

  useEffect(() => {
    if (modalType === "create") {
      handleClear();
      if (tempData.length > 0) {
        setCustomerName(tempData[0]?.CustomerName);
        setCustomerID(tempData[0]?.CustomerID);
        setDeliveryDate(tempData[0]?.DeliveryDate);
        setAdjudicator(tempData[0]?.Adjudicator);
      }
      // console.log(tempData);
    }
  }, [modalType, tempData]);

  // 如果在當頁點擊Menu的訂單建立 清空表單資料
  useEffect(() => {
    if (clearData) {
      handleClear();
    }
  }, [clearData]);

  useEffect(() => {
    // Cost 成本價
    // MarkedPrice 標價
    // SellingPrice 售價
    if (stringID) {
      setSelectableColor(
        stringData.filter((data) => data.StringID == stringID)?.[0]
          ?.ListStringColours
      );

      if (isNew) {
        setStringCost(0);
      } else {
        setStringCost(
          stringData.filter((data) => data.StringID === stringID)?.[0]
            ?.SellingPrice
        );
      }
    } else {
      setStringCost(0);
    }
  }, [stringID, isNew]);

  const handleClear = () => {
    setCustomerID("");
    setCustomerName("");
    setStringID("");
    setColour("");
    setFirstLbsValue("");
    setSecondLbsValue("");
    setMethod("");
    setIsNew(false);
    setDeposit(0);
    setAmountPayable(0);
    setPayStatus(false);
    setReceivedDate(new Date());
    setDeliveryDate(null);
    setAdjudicator("");
    setCost(0);
    setRemark("");
    // 加購區
    setGrommet([]);
    setUrgent([
      { AddOnCost: 0, AddOnID: 1, AddOnName: "正常案件", AddOnType: 2 },
    ]);
    setLogo([]);
    setOther([]);
    //線種價錢
    setStringCost(0);

    setSelectableColor([]);
  };

  const handleSave = async () => {
    // 姓名 電話 線種 磅數 交拍日 經手人
    // customerName !== "" &&
    // customerID !== "" &&
    // stringID !== "" &&
    // firstLbsValue !== "" &&
    // deliveryDate !== null &&
    // deliveryDate !== "";

    const checkData = await Promise.all([
      valueCheck(customerName, "customerName"),
      valueCheck(customerID, "customerID"),
      valueCheck(stringID, "stringID"),
      valueCheck(firstLbsValue, "firstLbsValue"),
      valueCheck(deliveryDate, "deliveryDate"),
      valueCheck(adjudicator, "adjudicator"),
    ]);
    setCheckDataIsValid(checkData);

    if (checkData.every((data) => data.status === true)) {
      // 未付清 + 新拍 => 訂金必填 ＋ 應付必填
      // 付清 + 新拍 => 訂金必填 ＋ 應付帶 0

      if (!mobileFormat(customerID)) {
        MySwal.fire({
          title: "手機號碼格式錯誤",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else if (isNew && !amountPayable && !deposit) {
        MySwal.fire({
          title: "訂金及應付金額不可皆為零",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && !amountPayable && !payStatus) {
      //   MySwal.fire({
      //     title: "應付金額不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }

      // else if (isNew && payStatus && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else {
        const order = {
          CustomerID: customerID,
          CustomerName: customerName,
          StringID: stringID,
          // ColourName: colourName,
          // ColourID: colourID,
          Colour: colour,
          Lbs: secondLbsValue
            ? `${firstLbsValue}/${secondLbsValue}`
            : firstLbsValue,
          Method: method,
          IsNew: isNew,
          Deposit: deposit ? Number(deposit) : 0,
          AmountPayable: amountPayable ? Number(amountPayable) : 0,
          PayStatus: payStatus ? 1 : 0,
          ReceivedDate: formatDate(receivedDate),
          DeliveryDate: deliveryFormatDate(deliveryDate),
          Adjudicator: adjudicator,
          Cost: cost,
          Remark: remark,
          ListOrderAddOn: [...grommet, ...urgent, ...logo, ...other],
          ListCustomize: customize,
        };

        setOrderList((orderList) => [
          ...orderList,
          { ...order, id: orderList.length + 1, isOpen: false },
        ]);

        setIsOpen(false);
      }
    } else {
      MySwal.fire({
        title: "尚未填妥所有必填欄位",
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            handleScroll();
          }, 300);
        }
      });
    }
  };

  useEffect(() => {
    //  非新拍
    if (!isNew) {
      // 清空cost
      setCost(0);
      let addCost;
      if (grommet && urgent && other && logo && customize) {
        // 加購項目
        addCost = [...grommet, ...urgent, ...logo, ...other, ...customize]
          ?.filter((item) => item.Enabled !== 0)
          ?.map((data) => data.AddOnCost)
          ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        // 線種金額 + 加購項目
        setCost(stringCost + addCost);

        // // 線種金額
        // if (stringCost) {
        //   setCost(stringCost + addCost);
        // }
      }

      if (payStatus) {
        setAmountPayable(0);
        // 已付金額為總金額
        setDeposit(stringCost + addCost);
      } else {
        setAmountPayable(stringCost + addCost);
        // 已付金額為 0
        setDeposit(0);
      }

      // 新拍
    } else {
      // 新拍 => 訂金 + 應付金額 + 免費線錢 + 免費案件費 ＋ 其他加購
      // 穿線付款為false
      setPayStatus(false);

      let isNewAddCost;
      if (grommet && other && logo) {
        isNewAddCost = [...grommet, ...logo, ...other, ...customize]
          ?.filter((item) => item.Enabled !== 0)
          ?.map((data) => data.AddOnCost)
          ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        // 線種金額
        if (stringCost) {
          setAmountPayable(0);
          setDeposit(0);
          setCost(stringCost + isNewAddCost);
        }
      }

      setCost(isNewAddCost + Number(amountPayable) + Number(deposit));

      // setAmountPayable(isNewAddCost + Number(amountPayable));
    }
  }, [
    grommet,
    urgent,
    logo,
    other,
    customize,
    stringCost,
    isNew,
    payStatus,
    deposit,
    amountPayable,
  ]);

  // useEffect(() => {
  //   if (grommet && urgent && other && logo) {
  //     const addCost = [...grommet, ...urgent, ...logo, ...other]
  //       ?.filter((item) => item.Enabled !== 0)
  //       ?.map((data) => data.AddOnCost)
  //       ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  //     if (stringCost) {
  //       setCost(stringCost + addCost);
  //     }
  //   }
  // }, [grommet, urgent, logo, other, stringCost]);

  // useEffect(() => {
  //   if (payStatus) {
  //     setAmountPayable(0);
  //   }
  // }, [payStatus, cost]);

  // useEffect(() => {
  //   if (!isNew) {
  //     if (grommet && urgent && other && logo) {
  //       const addCost = [...grommet, ...urgent, ...logo, ...other]
  //         ?.filter((item) => item.Enabled !== 0)
  //         ?.map((data) => data.AddOnCost)
  //         ?.reduce(
  //           (accumulator, currentValue) => accumulator + currentValue,
  //           0
  //         );

  //       if (stringCost) {
  //         setCost(stringCost + addCost);
  //       }
  //     }

  //     setDeposit(0);
  //   }
  // }, [isNew]);

  // useEffect(() => {
  //   // 未付清 + 新拍 => 訂金必填 ＋ 應付必填
  //   // 付清 + 新拍 => 訂金必填 ＋ 應付帶 0

  //   if (isNew) {
  //     // 新拍 => 訂金 + 應付金額 + 免費線錢 + 免費案件費 ＋ 其他加購
  //     const isNewAddCost = [...grommet, ...logo, ...other]
  //       ?.filter((item) => item.Enabled !== 0)
  //       ?.map((data) => data.AddOnCost)
  //       ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  //     setCost(Number(deposit) + Number(amountPayable) + isNewAddCost);
  //   }
  // }, [isNew, deposit, amountPayable]);

  useEffect(() => {
    if (editData.length > 0) {
      setIsOpen(true);
      setCustomerID(editData[0]?.CustomerID);
      setCustomerName(editData[0]?.CustomerName);
      setStringID(editData[0]?.StringID);

      // setColourName(editData[0]?.ColourName);
      // setColourID(editData[0]?.ColourID);
      setColour(editData[0]?.Colour);
      setFirstLbsValue(editData[0]?.Lbs?.split("/")?.[0]);
      setSecondLbsValue(editData[0]?.Lbs?.split("/")?.[1]);
      setMethod(editData[0]?.Method);
      setIsNew(editData[0]?.IsNew);
      setDeposit(editData[0]?.Deposit);
      setAmountPayable(editData[0]?.AmountPayable);
      setPayStatus(editData[0]?.PayStatus ? true : false);
      setReceivedDate(editData[0]?.ReceivedDate);
      setDeliveryDate(editData[0]?.DeliveryDate);
      setAdjudicator(editData[0]?.Adjudicator);
      setCost(editData[0]?.Cost);
      setRemark(editData[0]?.Remark);

      setGrommet(
        editData[0]?.ListOrderAddOn?.filter((data) => data.AddOnType == 1)
      );
      setUrgent(
        editData[0]?.ListOrderAddOn?.filter((data) => data.AddOnType == 2)
      );
      setLogo(
        editData[0]?.ListOrderAddOn?.filter((data) => data.AddOnType == 3)
      );
      setOther(
        editData[0]?.ListOrderAddOn?.filter((data) => data.AddOnType == 5)
      );

      setCustomize(
        editData[0]?.ListCustomize?.filter(
          (data) => data.AddOnType == 4
        )?.filter((item) => item?.AddOnName !== "")
      );
    }
  }, [editData]);

  const handleEdit = async () => {
    const checkData = await Promise.all([
      valueCheck(customerName, "customerName"),
      valueCheck(customerID, "customerID"),
      valueCheck(stringID, "stringID"),
      valueCheck(firstLbsValue, "firstLbsValue"),
      valueCheck(deliveryDate, "deliveryDate"),
    ]);
    setCheckDataIsValid(checkData);

    if (checkData.every((data) => data.status === true)) {
      // 未付清 + 新拍 => 訂金必填 ＋ 應付必填
      // 付清 + 新拍 => 訂金必填 ＋ 應付帶 0

      if (!mobileFormat(customerID)) {
        MySwal.fire({
          title: "手機號碼格式錯誤",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else if (isNew && !amountPayable && !deposit) {
        MySwal.fire({
          title: "訂金及應付金額不可皆為零",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && payStatus && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else {
        const data = {
          CustomerID: customerID,
          CustomerName: customerName,
          StringID: stringID,
          // ColourName: colourName,
          // ColourID: colourID,
          Colour: colour,
          Lbs: secondLbsValue
            ? `${firstLbsValue}/${secondLbsValue}`
            : firstLbsValue,
          Method: method,
          IsNew: isNew,
          Deposit: deposit ? Number(deposit) : 0,
          AmountPayable: amountPayable ? Number(amountPayable) : 0,
          PayStatus: payStatus ? 1 : 0,
          ReceivedDate: formatDate(receivedDate),
          DeliveryDate: deliveryFormatDate(deliveryDate),
          Adjudicator: adjudicator,
          Cost: cost,
          Remark: remark,
          ListOrderAddOn: [...grommet, ...urgent, ...logo, ...other],
          ListCustomize: customize,
          id: editData[0].id,
          isOpen: false,
        };

        setOrderList(
          orderList.map((item) => {
            if (item.id === editData[0].id) {
              return data;
            } else {
              return item;
            }
          })
        );

        setIsOpen(false);
      }
    } else {
      MySwal.fire({
        title: "尚未填妥所有必填欄位",
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            handleScroll();
          }, 300);
        }
      });
    }
  };

  useEffect(() => {
    if (typeof colour === "number") {
      setColourID(colour);
    }
    if (typeof colour === "string") {
      setColourName(colour);
    }
  }, [colour]);

  return (
    <Wrapper>
      <Container>
        <CloseIcon onClick={handleCloseFun}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseIcon>

        <Title>
          {modalType === "create" ? "新增" : "編輯"}
          訂單
        </Title>
        <ScrollContainer ref={scrollRef}>
          <FormWrapper>
            <Input
              title={"姓名"}
              onChangeFun={setCustomerName}
              value={customerName}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "customerName"
                )[0]?.status === false
              }
            />
            <Input
              title={"電話"}
              onChangeFun={setCustomerID}
              value={customerID}
              required={true}
              invalid={
                checkDataIsValid.filter((data) => data.type === "customerID")[0]
                  ?.status === false
              }
            />
            <SearchInput
              title={"線種"}
              onChangeFun={setStringID}
              value={stringID}
              required={true}
              invalid={
                checkDataIsValid.filter((data) => data.type === "stringID")[0]
                  ?.status === false
              }
            />
            <Input title={"顏色"} onChangeFun={setColour} value={colour} />
            {/* <InputSelect
              title={"顏色"}
              onChangeFun={setColour}
              value={colour}
              selectableColor={selectableColor}
              placeholder={"請先選擇線種"}
            /> */}

            <CombineInput
              title={"磅數"}
              firstValue={firstLbsValue}
              secondValue={secondLbsValue}
              onChangeFirstFun={setFirstLbsValue}
              onChangeSecondFun={setSecondLbsValue}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "firstLbsValue"
                )[0]?.status === false
              }
            />
            <Select
              title={"穿線法"}
              group={"StringMethod"}
              onChangeFun={setMethod}
              value={method}
            />

            <Calendar
              title={"收拍日"}
              onChangeFun={setReceivedDate}
              selectDate={receivedDate}
              minDate={true}
            />

            <Calendar
              title={"交拍日"}
              onChangeFun={setDeliveryDate}
              selectDate={deliveryDate}
              minDate={true}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "deliveryDate"
                )[0]?.status === false
              }
            />

            <Select
              title={"經手人"}
              group={"Staff"}
              onChangeFun={setAdjudicator}
              value={adjudicator}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "adjudicator"
                )[0]?.status === false
              }
            />
          </FormWrapper>

          <TextareaWrapper>
            <Textarea title={"備註"} onChangeFun={setRemark} value={remark} />
          </TextareaWrapper>

          <AddTitle>加購區</AddTitle>
          <AddWrapper>
            <AddContainer>
              <RadioButton
                title={"孔丁"}
                group={"Grommet"}
                value={grommet?.[0]?.AddOnID}
                onChangeFun={setGrommet}
                addData={grommet}
              />

              <RadioButton
                title={"案件"}
                group={"Urgent"}
                value={urgent?.[0]?.AddOnID}
                onChangeFun={setUrgent}
                addData={urgent}
                disabled={isNew}
              />
              <RadioButton
                title={"Logo"}
                group={"Logo"}
                value={logo?.[0]?.AddOnID}
                onChangeFun={setLogo}
                addData={logo}
              />
              <RadioButton
                title={"其他"}
                group={"Other"}
                value={other?.[0]?.AddOnID}
                onChangeFun={setOther}
                addData={other}
              />
              <AddInput
                title={"自訂"}
                group={"Customize"}
                namePlaceholder={"名稱"}
                costPlaceholder={"價格"}
                addData={customize}
                nameValue={customize?.[0]?.AddOnName}
                costValue={customize?.[0]?.AddOnCost}
                onChangeFun={setCustomize}
              />
            </AddContainer>
          </AddWrapper>

          <FormWrapper>
            <Checkbox
              title={"穿線付款"}
              onChangeFun={setPayStatus}
              value={payStatus}
              disabled={isNew}
            />

            <Checkbox title={"新拍"} onChangeFun={setIsNew} value={isNew} />

            {/* 新拍顯示訂金 + 應付金額 */}
            {isNew && (
              <Input
                title={"訂金"}
                onChangeFun={setDeposit}
                value={deposit}
                disabled={!isNew}
              />
            )}

            {isNew && (
              <Input
                title={"應付金額"}
                onChangeFun={setAmountPayable}
                value={amountPayable}
                disabled={!isNew || (isNew && payStatus)}
              />
            )}
          </FormWrapper>

          <TotalAmountWrapper>
            <TotalAmountContainer>
              <TotalAmountText>總金額</TotalAmountText>
              <TotalAmount>$ {cost}</TotalAmount>
            </TotalAmountContainer>
            <TotalAmountContainer>
              <TotalAmountText>已付金額</TotalAmountText>
              <TotalAmount>$ {deposit === "" ? 0 : deposit}</TotalAmount>
            </TotalAmountContainer>
            <TotalAmountContainer>
              <TotalAmountText>應付金額</TotalAmountText>
              <TotalAmount>
                $ {amountPayable === "" ? 0 : amountPayable}
              </TotalAmount>
            </TotalAmountContainer>
          </TotalAmountWrapper>
          {modalType === "create" ? (
            <ButtonWrapper>
              <ButtonContainer>
                <Button text={"清除資料"} onClickFun={handleClear} />
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  text={"完成訂單"}
                  onClickFun={handleSave}
                  // disabled={!isValid}
                />
              </ButtonContainer>
            </ButtonWrapper>
          ) : (
            <ButtonContainer>
              <Button
                text={"更新訂單"}
                onClickFun={handleEdit}
                // disabled={!isValid}
              />
            </ButtonContainer>
          )}
        </ScrollContainer>
      </Container>
    </Wrapper>
  );
};

export default OrderLayout;
