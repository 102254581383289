import { styled, css } from "styled-components";
import { useEffect, useState, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faL } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
`;

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: #003060;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SelectLabelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  /* height: 3.7vh; */

  cursor: pointer;
  transition: 0.3s ease;
  /* font-size: 0.8vw; */
`;

const InputEl = styled.input`
  padding: 10px 20px;
  background: #fafafa;
  border: none;
  width: 100%;
`;

const ArrowIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 10px;
  cursor: pointer;

  transition: 0.3s ease;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: scaleY(-1);
    `}/* border: 1px solid blue; */
`;

const DropdownStyle = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;

  max-height: 20vmax;
  z-index: 5;
  width: 100%;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

const Item = styled.div`
  padding: 10px 20px;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;

  /* font-size: 0.9375vw; */

  /* ${(props) =>
    props.active &&
    css`
      color: #009a63;
      font-weight: 500;
    `} */
  &:hover,
  :focus,
  :focus:hover {
    background-color: #16161a;
    color: #fafafa;
    outline: none;
  }
`;

const InputSelect = ({
  title,
  onChangeFun,
  value,
  selectableColor,
  placeholder,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
      ref.current.value = value;
    }
  }, [value]);

  //   useEffect(() => {
  //     console.log(value);
  //     if (value) {
  //       setCurrentValue(value);
  //     } else {
  //       ref.current.value = "";
  //       setCurrentValue("");
  //     }
  //   }, [value]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleValueChange = (value) => {
    setCurrentValue(value);
  };

  const handleChange = (value) => {
    handleValueChange(value);
    if (onChangeFun) onChangeFun(value);
    setOpen(false);
  };

  const handleText = (value) => {
    setCurrentValue("");
    setOpen(false);

    if (value !== "") {
      onChangeFun(value);
    }
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <SelectContainer>
        <Fragment>
          <SelectLabelButton onClick={handleOpen}>
            <InputEl
              placeholder={placeholder}
              ref={ref}
              value={
                selectableColor?.filter(
                  (data) => data.ColourID == currentValue
                )[0]?.ColourName || ""
              }
              onChange={(e) => handleText(e.target.value)}
            />

            {selectableColor && (
              <ArrowIcon $isVisible={open}>
                <FontAwesomeIcon icon={faChevronDown} />
              </ArrowIcon>
            )}
          </SelectLabelButton>
          <DropdownStyle $isVisible={open}>
            {selectableColor &&
              selectableColor.map((data) => (
                <DropdownItem
                  value={data.ColourID}
                  key={data.ColourID}
                  onClick={() => handleChange(data.ColourID)}
                >
                  <Item>{data.ColourName}</Item>
                </DropdownItem>
              ))}
          </DropdownStyle>
        </Fragment>
      </SelectContainer>
    </Wrapper>
  );
};

export default InputSelect;
