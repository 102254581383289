import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  List: {
    Colour: [],
    Grommet: [],
    Logo: [],
    Manufacturer: [],
    Customize: [], // 自訂
    Other: [], // 其他 => 跳線
    Roles: [],
    Staff: [],
    StringMethod: [],
    Urgent: [],
  },
  String: [],
};

// Config slice
export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    getDropDownList: (state, action) => {
      Object.keys(initialState.List).forEach(
        (key) =>
          (state.List[key] = action.payload.SelectionGroup.filter(
            (data) => data.GroupName === key
          ))
      );
    },
    getStrings: (state, action) => {
      state.String = action?.payload?.ListGetStrings;
    },
    // getStaff: (state, action) => {
    //   state.Staff = action?.payload?.ListGetGromment;
    // },
  },
});

// Export actions
export const { getDropDownList, getStrings, getStaff } = systemSlice.actions;

// Export select
export const selectDropDown = (state) => state.system;

// Export reducer
export default systemSlice.reducer;
