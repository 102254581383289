import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${RWD_SM(css`
    flex-direction: column;
    align-items: flex-start;
  `)};
`;

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: #003060;
  width: 15%;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const LabelContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-gap: 10px;
  width: 85%;

  ${RWD_SM(css`
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  `)};
`;

const LabelEl = styled.label`
  display: flex;
  align-items: center;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const LabelTitle = styled.div`
  padding-left: 5px;
`;

const RadioEl = styled.input.attrs({ type: "radio" })`
  accent-color: #16161a;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const CheckboxEl = styled.input.attrs({ type: "checkbox" })`
  accent-color: #16161a;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const RadioButton = ({
  title,
  group,
  onChangeFun,
  listData,
  type,
  value,
  addData,
  disabled,
}) => {
  // params
  // AddOnType 1 孔丁
  // AddOnType 2 案件
  // AddOnType 3 Logo
  // AddOnType 4 自訂
  // AddOnType 5 跳線 連釘

  const data = useSelector(selectDropDown).List;

  const handleSelect = (group, value, name) => {
    switch (group) {
      // case "Grommet":
      //   if (type === "update") {
      //     console.log("修改孔丁");
      //     const singleSelect = data[group]?.[0]?.Options.map((item) => {
      //       if (item.Others.isMultiSelect === 0) {
      //         return item.Value;
      //       } else {
      //         return;
      //       }
      //     });

      //     // 原本就有的選項
      //     if (
      //       addData
      //         .filter((data) => data.AddOnID === value)[0]
      //         ?.hasOwnProperty("OrderAddOnID")
      //     ) {
      //       if (singleSelect.indexOf(value) > -1) {
      //         console.log("原本就有的選項 選擇0");
      //         return onChangeFun([
      //           {
      //             ...addData.filter((data) => data.AddOnID === value)[0],
      //             Enabled:
      //               typeof addData.filter((data) => data.AddOnID === value)[0]
      //                 ?.Enabled == "undefined"
      //                 ? 0
      //                 : addData.filter((data) => data.AddOnID === value)[0]
      //                     ?.Enabled === 1
      //                 ? 0
      //                 : 1,
      //           },
      //           ...addData.filter(
      //             (item) => singleSelect.indexOf(item.AddOnID) === -1
      //           ),
      //         ]);
      //       } else {
      //         console.log("原本就有的選項 選擇1");
      //         return onChangeFun([
      //           {
      //             ...addData.filter((data) => data.AddOnID === value)[0],
      //             Enabled:
      //               typeof addData.filter((data) => data.AddOnID === value)[0]
      //                 ?.Enabled == "undefined"
      //                 ? 0
      //                 : addData.filter((data) => data.AddOnID === value)[0]
      //                     ?.Enabled === 1
      //                 ? 0
      //                 : 1,
      //           },
      //           ...addData.filter(
      //             (item) => singleSelect.indexOf(item.AddOnID) > -1
      //           ),
      //         ]);
      //       }
      //     } else {
      //       // 原本沒有的選項
      //       // 選擇 0
      //       if (singleSelect.indexOf(value) > -1) {
      //         console.log("原本沒有的選項 選擇0");

      //         if (addData.filter((item) => item.AddOnID === value).length > 0) {
      //           return onChangeFun([
      //             ...addData
      //               .filter(
      //                 (item) =>
      //                   singleSelect.indexOf(item.AddOnID) > -1 &&
      //                   item.hasOwnProperty("OrderAddOnID")
      //               )
      //               .map((data) => {
      //                 return {
      //                   ...data,
      //                   Enabled: 0,
      //                 };
      //               }),
      //             ...addData.filter(
      //               (item) => singleSelect.indexOf(item.AddOnID) === -1
      //             ),
      //           ]);
      //         } else {
      //           return onChangeFun([
      //             ...addData
      //               .filter(
      //                 (item) =>
      //                   singleSelect.indexOf(item.AddOnID) > -1 &&
      //                   item.hasOwnProperty("OrderAddOnID")
      //               )
      //               .map((data) => {
      //                 return {
      //                   ...data,
      //                   Enabled: 0,
      //                 };
      //               }),
      //             ...addData.filter(
      //               (item) => singleSelect.indexOf(item.AddOnID) === -1
      //             ),

      //             {
      //               AddOnType: 1,
      //               AddOnID: value,
      //               AddOnName: name,
      //               AddOnCost: data[group]?.[0]?.Options?.filter(
      //                 (item) => item.Value == value
      //               )?.[0]?.Others?.Cost,
      //               Enabled: 1,
      //             },
      //           ]);
      //         }
      //       } else {
      //         if (
      //           addData.filter(
      //             (item) => singleSelect.indexOf(item.AddOnID) === -1
      //           ).length > 0
      //         ) {
      //           return onChangeFun([
      //             ...addData.filter(
      //               (item) => singleSelect.indexOf(item.AddOnID) > -1
      //             ),
      //           ]);
      //         } else {
      //           return onChangeFun([
      //             ...addData,
      //             {
      //               AddOnType: 1,
      //               AddOnID: value,
      //               AddOnName: name,
      //               AddOnCost: data[group]?.[0]?.Options?.filter(
      //                 (item) => item.Value == value
      //               )?.[0]?.Others?.Cost,
      //               Enabled: 1,
      //             },
      //           ]);
      //         }
      //       }
      //     }
      //   } else {
      //     if (addData.filter((data) => data.AddOnID === value).length > 0) {
      //       return onChangeFun(
      //         addData.filter((data) => data.AddOnID !== value)
      //       );
      //     } else {
      //       if (
      //         data[group]?.[0]?.Options?.filter(
      //           (item) => item.Value == value
      //         )?.[0]?.Others?.isMultiSelect === 0
      //       ) {
      //         return onChangeFun([
      //           ...addData.filter((data) => data.isMultiSelect !== 0),
      //           {
      //             AddOnType: 1,
      //             AddOnID: value,
      //             AddOnName: name,
      //             AddOnCost: data[group]?.[0]?.Options?.filter(
      //               (item) => item.Value == value
      //             )?.[0]?.Others?.Cost,
      //             isMultiSelect: data[group]?.[0]?.Options?.filter(
      //               (item) => item.Value == value
      //             )?.[0]?.Others?.isMultiSelect,
      //           },
      //         ]);
      //       } else {
      //         return onChangeFun([
      //           ...addData,
      //           {
      //             AddOnType: 1,
      //             AddOnID: value,
      //             AddOnName: name,
      //             AddOnCost: data[group]?.[0]?.Options?.filter(
      //               (item) => item.Value == value
      //             )?.[0]?.Others?.Cost,
      //             isMultiSelect: data[group]?.[0]?.Options?.filter(
      //               (item) => item.Value == value
      //             )?.[0]?.Others?.isMultiSelect,
      //           },
      //         ]);
      //       }
      //     }
      //   }

      case "Grommet":
        if (type === "update") {
          console.log("修改孔丁");
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([
              {
                ...addData[0],
                Enabled: addData[0]?.Enabled == 1 ? 0 : 1,
              },
            ]);
          } else {
            return onChangeFun([
              {
                AddOnType: 1,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
                Enabled: 1,
                OrderAddOnID: addData[0]?.OrderAddOnID,
              },
            ]);
          }
        } else {
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([]);
          } else {
            return onChangeFun([
              {
                AddOnType: 1,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
              },
            ]);
          }
        }

      case "Urgent":
        if (type === "update") {
          console.log("修改案件");
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([
              {
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
                OrderAddOnID: addData[0]?.OrderAddOnID,
                Enabled: 1,
              },
            ]);
          } else {
            return onChangeFun([
              {
                AddOnType: 2,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
                Enabled: 1,
                OrderAddOnID: addData[0]?.OrderAddOnID,
              },
            ]);
          }
        } else {
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([
              {
                AddOnType: 2,
                AddOnID: 1,
                AddOnName: "正常案件",
                AddOnCost: 0,
              },
            ]);
          } else {
            return onChangeFun([
              {
                AddOnType: 2,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
              },
            ]);
          }
        }

      case "Logo":
        if (type === "update") {
          console.log("修改logo");

          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([
              {
                ...addData[0],
                Enabled: addData[0]?.Enabled == 1 ? 0 : 1,
              },
            ]);
          } else {
            if (addData[0]?.hasOwnProperty("OrderAddOnID")) {
              return onChangeFun([
                {
                  AddOnType: 3,
                  AddOnID: value,
                  AddOnName: name,
                  AddOnCost: data[group]?.[0]?.Options?.filter(
                    (item) => item.Value == value
                  )?.[0]?.Others?.Cost,
                  Enabled: 1,
                  OrderAddOnID: addData[0]?.OrderAddOnID,
                },
              ]);
            } else {
              return onChangeFun([
                {
                  AddOnType: 3,
                  AddOnID: value,
                  AddOnName: name,
                  AddOnCost: data[group]?.[0]?.Options?.filter(
                    (item) => item.Value == value
                  )?.[0]?.Others?.Cost,
                  Enabled: 1,
                },
              ]);
            }
          }
        } else {
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([]);
          } else {
            return onChangeFun([
              {
                AddOnType: 3,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
              },
            ]);
          }
        }

      case "Other":
        if (type === "update") {
          console.log("修改其他 => 連釘 / 跳線");
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            return onChangeFun([
              {
                ...addData[0],
                Enabled: addData[0]?.Enabled == 1 ? 0 : 1,
              },
            ]);
          } else {
            if (addData[0]?.hasOwnProperty("OrderAddOnID")) {
              return onChangeFun([
                {
                  AddOnType: 5,
                  AddOnID: value,
                  AddOnName: name,
                  AddOnCost: data[group]?.[0]?.Options?.filter(
                    (item) => item.Value == value
                  )?.[0]?.Others?.Cost,
                  Enabled: 1,
                  OrderAddOnID: addData[0]?.OrderAddOnID,
                },
              ]);
            } else {
              return onChangeFun([
                {
                  AddOnType: 5,
                  AddOnID: value,
                  AddOnName: name,
                  AddOnCost: data[group]?.[0]?.Options?.filter(
                    (item) => item.Value == value
                  )?.[0]?.Others?.Cost,
                  Enabled: 1,
                },
              ]);
            }
          }
        } else {
          console.log("選擇其他");
          if (addData.filter((data) => data.AddOnID === value).length > 0) {
            console.log(value);
            console.log(addData);
            return onChangeFun(
              addData.filter((data) => data.AddOnID !== value)
            );
          } else {
            return onChangeFun([
              ...addData,
              {
                AddOnType: 5,
                AddOnID: value,
                AddOnName: name,
                AddOnCost: data[group]?.[0]?.Options?.filter(
                  (item) => item.Value == value
                )?.[0]?.Others?.Cost,
              },
            ]);
          }
        }

      default:
        break;
    }
  };

  const fakeFun = (e) => {};

  return (
    <Wrapper>
      <Title>{title}</Title>
      <LabelContainer>
        {data &&
          data[group]?.[0]?.Options.map((item) => (
            <LabelEl htmlFor={item.Value} key={item.Value}>
              {group === "Other" ? (
                <Fragment>
                  <CheckboxEl
                    id={item.Value}
                    value={item.Value}
                    name={group}
                    checked={
                      addData
                        ?.filter((data) => data.AddOnType === 5)
                        ?.filter(
                          (el) => el.AddOnID === item.Value && el.Enabled !== 0
                        ).length
                      // ? value == item.Value
                      // : item.Others.Default
                    }
                    disabled={disabled}
                    onChange={() => handleSelect(group, item.Value, item.Text)}
                  />
                </Fragment>
              ) : (
                <RadioEl
                  id={item.Value}
                  value={item.Value}
                  name={group}
                  checked={
                    value == item.Value && addData[0].Enabled !== 0
                    // ? value == item.Value
                    // : item.Others.Default
                  }
                  disabled={disabled}
                  onClick={() => handleSelect(group, item.Value, item.Text)}
                />
              )}

              <LabelTitle>
                {item.Text} (${item.Others.Cost})
              </LabelTitle>
            </LabelEl>
          ))}
      </LabelContainer>
    </Wrapper>
  );
};

export default RadioButton;
