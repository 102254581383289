import { styled, keyframes, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Bg from "../../images/bg.jpg";
import { useState, useEffect } from "react";
import { useLoginMutation } from "../../services/loginApi";
import { useGetUserAuthMutation } from "../../services/authApi";

import { useDispatch } from "react-redux";
import { setUser, setAuthFunctions } from "../../slices/loginSlice";
import { useNavigate } from "react-router-dom";
import { getAuthToken, clearAuthFun, clearAuthToken } from "../../utils";

import Loading from "../components/Loading";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const Wrapper = styled.div`
  height: 100vh;
  background-image: url(${Bg});
  background-size: cover;
  background-position: center center;
`;

const Container = styled.div`
  display: flex;
`;

const Cover = styled.div`
  display: flex;
  background: linear-gradient(180deg, black, transparent);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FormWrapper = styled.div`
  min-width: 50%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(280px, 90vw, 500px);
  background-color: #fcfcfc;
  /* min-height: 50vh; */
  border-radius: 8px;
  padding: 50px 0;

  /* ${RWD_SM(css`
    width: 90%;
  `)}; */
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
`;

const FormTitle = styled.div`
  text-align: left;
  width: 80%;
  padding-bottom: 50px;
  font-size: 24px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  /* flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; */
  padding-bottom: 30px;
  width: 100%;
`;

const InputEl = styled.input`
  padding: 10px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
`;

const LabelEl = styled.label`
  width: 100%;
  text-align: left;
  padding-bottom: 5px;
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 15px 0;
  background: #16161a;
  color: #fcfcfc;
  border: none;
  cursor: pointer;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
`;

const LoginPage = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userNo, setUserNo] = useState("");
  const [password, setPassword] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  // login
  const [isLoggedIn, setIsLoggedIn] = useState(getAuthToken().token);

  const [_login, { isSuccess, data, isError, error, isLoading }] =
    useLoginMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser({ token: data.AccessToken, user: userNo }));
      // 拿到Token後 取得使用者auth
      _getAuth({
        UserNo: getAuthToken().user,
      });
    }

    if (isError) {
      MySwal.fire({
        title: "登入失敗，請重新登入",
        text: error?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        allowOutsideClick: false,
        confirmButtonColor: "#16161a",
      }).then((result) => {
        if (result.isConfirmed) {
          // 清除Token 和 權限
          clearAuthToken();
          clearAuthFun();
          navigate("/sys/stringsys");
        }
      });
      console.log(error?.data?.Message);
    }
  }, [data, isError]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      UserNo: userNo,
      Password: password,
      CompanyCode: "dev",
    };

    await _login(data);
  };

  useEffect(() => {
    if (isLoggedIn) {
      _getAuth({
        UserNo: getAuthToken().user,
      });
    }
  }, [isLoggedIn]);

  // auth
  const [
    _getAuth,
    {
      data: authData,
      isSuccess: authIsSuccess,
      isError: authIsError,
      error: authError,
      isLoading: authIsLoading,
    },
  ] = useGetUserAuthMutation();

  useEffect(() => {
    if (authIsSuccess) {
      // 導到訂單紀錄
      dispatch(setAuthFunctions(authData));
      navigate("dashboard/orderRecord");
    }
    if (authIsError) {
      MySwal.fire({
        title: "登入失敗，請重新登入",
        text: authError?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        allowOutsideClick: false,
        confirmButtonColor: "#16161a",
      }).then((result) => {
        if (result.isConfirmed) {
          // 清除Token 和 權限
          clearAuthToken();
          clearAuthFun();
          navigate("/sys/stringsys");
        }
      });
      console.log(authError?.data?.Message);
    }
  }, [authData, authIsError]);

  if (isLoggedIn && authIsSuccess) {
    return (
      <Navigate
        to={`${process.env.REACT_APP_PATH}/dashboard/orderRecord`}
        replace
      />
    );
  }
  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <Cover>
          <FormWrapper>
            <FormContainer>
              <FormTitle>Stringing System</FormTitle>
              <Form>
                <Inputs>
                  <InputWrapper>
                    <LabelEl>帳號</LabelEl>
                    <InputEl
                      placeholder="example@mail.com"
                      //   type="email"
                      onChange={(e) => setUserNo(e.target.value)}
                    ></InputEl>
                  </InputWrapper>
                  <InputWrapper>
                    <LabelEl>密碼</LabelEl>
                    <InputEl
                      placeholder="6-12位英數字密碼，請區分大小寫"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    ></InputEl>
                  </InputWrapper>
                </Inputs>
                <LoginButton onClick={handleLogin}>登入</LoginButton>
              </Form>
            </FormContainer>
          </FormWrapper>
        </Cover>

        {/* <Wave></Wave> */}
      </Container>
    </Wrapper>
  );
};

export default LoginPage;
