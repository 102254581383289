import { styled, css } from "styled-components";
import Calendar from "../../global/Calendar";
import Input from "../../global/Input";
import Button from "../../global/Button";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useState, useEffect, useRef } from "react";

import { useGetOrderOfTheDayMutation } from "../../services/stringingApi";

import { formatDate } from "../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleExclamation,
  faPrint,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams, useLocation } from "react-router-dom";

import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { selectDropDown } from "../../slices/systemSlice";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 50px;
`;

const TitleWrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  padding: 20px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const TableTitle = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  text-align: left;
  padding-top: 30px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const TableHeaderItem = styled.div`
  color: #b3bfbf;
  padding: 20px;
`;

const OrderContainer = styled.div`
  padding: 20px 0;
  background: #fafafa;
  display: flex;
  justify-content: space-evenly;
  border-radius: 5px;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  flex: 1;
  /* color: #ccc; */
`;

const NoDataIcon = styled.div`
  font-size: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const NoDataText = styled.div`
  padding: 40px 0;
`;

const OrderCardWrapper = styled.div`
  /* border: 1px solid blue; */
  padding: 20px 0 100px 0;
`;

const OrderCardContainer = styled.div`
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
`;

const OrderCard = styled.div`
  border: 1px solid #16161a;
  /* background: #fafafa; */
  display: flex;
  flex-direction: column;
`;

const OrderCardIdWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;

  ${RWD_SM(css`
    padding: 0;
    flex-direction: column-reverse;
  `)};
`;

const OrderCardId = styled.div`
  padding: 10px 10px;
  font-weight: 500;
  text-align: start;

  ${RWD_SM(css`
    border-top: 1px solid #ccc;
  `)};
`;

const OrderCardItemWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $type }) => ($type ? "15% auto" : "30% auto")};

  ${RWD_SM(css`
    grid-column: 1 / 1;
    grid-template-columns: 30% auto;
    /* grid-template-rows: repeat(1, 1fr); */
  `)};
`;

const OrderCardItem = styled.div`
  /* background: #fafafa; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
`;

const OrderCardText = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
`;

const OrderCardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(10, 1fr); */

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 20px;
  padding: 20px 0 40px 0; */

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-rows: repeat(1, 1fr); */
  `)};
`;

const OrderCost = styled.div`
  font-weight: bold;
  font-size: 30px;
  text-align: end;
  padding: 20px;
`;

const FunctionWrapper = styled.div`
  display: flex;
  padding-right: 5px;

  ${RWD_SM(css`
    padding: 10px 5px 10px 0;
  `)};
`;

const FunctionList = styled.button`
  background-color: antiquewhite;
  padding: 5px 10px;
  color: #16161a;
  background: #fafafa;
  border: 1px solid #16161a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: ${({ $status }) => ($status ? "default" : "pointer")};
  color: ${({ $status }) => ($status ? "#ccc" : " #16161a")};
  border: ${({ $status }) =>
    $status ? "1px solid #ccc " : "1px solid #1e2022"};

  &:last-child {
    margin-right: 0px;
  }
`;

const FunctionContainer = styled.div`
  width: 100%;
  /* border: 1px solid #16161a; */
  display: flex;

  justify-content: flex-end;
`;

const FunctionIcon = styled.div``;

const FunctionText = styled.div`
  padding-left: 10px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const PrinterButtonWrapper = styled.div`
  width: 100px;
  margin-left: auto;
`;

const OrderDateRecordPage = () => {
  const { connection, ip, connect, connectIsLoading, print } =
    useOutletContext();

  const MySwal = withReactContent(Swal);
  const grommetData = useSelector(selectDropDown).List?.Grommet;
  const stringData = useSelector(selectDropDown)?.String;
  const colourData = useSelector(selectDropDown).List?.Colour;
  const methodData = useSelector(selectDropDown).List?.StringMethod;
  const staffData = useSelector(selectDropDown).List?.Staff;

  const orderDate = {
    ReceivedDate: `${useParams()?.orderDate.slice(
      0,
      4
    )}-${useParams()?.orderDate.slice(4, 6)}-${useParams()?.orderDate.slice(
      6,
      8
    )}`,
  };

  const [recordList, setRecordList] = useState([]);

  const [_getData, { data, isSuccess, isError, error, isLoading }] =
    useGetOrderOfTheDayMutation();
  const { search } = useLocation();

  useEffect(() => {
    const query_name = new URLSearchParams(search).get("name");
    const query_phone = new URLSearchParams(search).get("phone");

    const data = {
      ReceivedDate: orderDate.ReceivedDate,
      CustomerName: query_name,
      CustomerID: query_phone,
    };

    _getData(data);
  }, [search]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setRecordList(data.ListGetOrderOfTheDay);
    }

    if (isError) {
      console.log(data?.error?.Messgae);
    }
  }, [data, isError]);

  const handleNavigate = (orderId, orderNo) => {
    // console.log(date);
    // navigate(`${process.env.REACT_APP_PATH}/${date}`);
    navigate(
      `${process.env.REACT_APP_PATH}/dashboard/orderRecord/order/${orderId}_${orderNo}`
    );
  };

  const header = [
    {
      id: 1,
      name: "客戶姓名",
      value: "CustomerName",
      width: "10",
    },
    {
      id: 2,
      name: "電話",
      value: "CustomerID",
      width: "10",
    },
    {
      id: 3,
      name: "線種",
      value: "StringID",
      width: "10",
    },
    {
      id: 4,
      name: "顏色",
      value: "ColourName",
      width: "10",
    },
    {
      id: 5,
      name: "磅數",
      value: "Lbs",
      width: "10",
    },
    {
      id: 6,
      name: "穿線法",
      value: "Method",
      width: "10",
    },
    {
      id: 7,
      name: "收拍日",
      value: "ReceivedDate",
      width: "10",
    },
    {
      id: 8,
      name: "交拍日",
      value: "DeliveryDate",
      width: "10",
    },
    {
      id: 9,
      name: "經手人",
      value: "Adjudicator",
      width: "10",
    },
    {
      id: 10,
      name: "穿線付款",
      value: "PayStatus",
      width: "10",
    },
    {
      id: 11,
      name: "加購項目",
      value: "ListOrderAddOn",
      width: "10",
    },
    {
      id: 12,
      name: "其他",
      value: "Other",
      width: "10",
    },
    {
      id: 13,
      name: "備註",
      value: "Remark",
      width: "10",
    },
  ];

  const [printerIPAddress, setPrinterIPAddress] =
    useState("125.228.238.87:777");
  const [printerPort, setPrinterPort] = useState("8043");
  // const [connectionStatus, setConnectionStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const ePosDevice = useRef();
  const printer = useRef();

  const STATUS_CONNECTED = "connected";

  // 外網
  // 125.228.238.87:777
  // 內網
  // 192.168.50.111:9100

  // useEffect(() => {
  //   const scriptEl = Array.from(document.getElementsByTagName("script"));

  //   const printerTag = scriptEl.filter((data) => data.src.includes("epos"));
  //   if (printerTag.length > 0) {
  //     return;
  //   } else {
  //     const script = document.createElement("script");
  //     script.src = "/epos-2.23.0.js";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  // }, []);

  // const connect = () => {
  //   // setConnectionStatus("Connecting ...");
  //   setLoading(true);

  //   if (!printerIPAddress) {
  //     // setConnectionStatus("Type the printer IP address");
  //     return;
  //   }
  //   if (!printerPort) {
  //     // setConnectionStatus("Type the printer port");
  //     return;
  //   }

  //   let ePosDev = new window.epson.ePOSDevice();
  //   ePosDevice.current = ePosDev;

  //   console.log(ePosDev);

  //   ePosDev.connect(printerIPAddress, printerPort, (data) => {
  //     if (data === "OK" || data === "SSL_CONNECT_OK") {
  //       setLoading(false);
  //       ePosDev.createDevice(
  //         "local_printer",
  //         ePosDev.DEVICE_TYPE_PRINTER,
  //         { crypto: true, buffer: false },
  //         (devobj, retcode) => {
  //           if (retcode === "OK") {
  //             printer.current = devobj;
  //             // setConnectionStatus(STATUS_CONNECTED);
  //           } else {
  //             throw retcode;
  //           }
  //         }
  //       );
  //     } else {
  //       setLoading(false);
  //       MySwal.fire({
  //         title: "連線失敗",
  //         text: data,
  //         icon: "error",
  //         confirmButtonText: "確認",
  //         confirmButtonColor: "#16161a",
  //       });
  //       throw data;
  //     }
  //   });
  // };

  const handlePrint = (id, num) => {
    const totalCount = recordList.filter((data) => data.OrderID === num).length;
    const printObject = recordList.filter(
      (data) => data.OrderID === num && data.OrderNo === id
    );

    const startEnvelope = `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header>
    <parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    <devid>local_printer</devid>
    <timeout>10000</timeout>
    </parameter>
    </s:Header>
    <s:Body>
    <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    `;

    const endEnvelope = `
    </epos-print>
    </s:Body>
    </s:Envelope>
    `;

    const req = `
    <page>
    <area x="0" y="0" width="375" height="1090"/>
    <text lang="zh-tw"/>
    <text smooth="true"/>
    <direction dir="top_to_bottom"/>
    <text font="font_a"/>
    <text align="center"/>
    <feed/>
    <text align="left"/>
    <position x="1" y="40"/>
    <text>電話</text>
    <text width="2" height="2"/>
    <position x="160" y="40"/>
    <text>${printObject[0]?.CustomerID}</text>
    <text width="1" height="1"/>
    <position x="1" y="100"/>
    <text>姓名</text>
    <position x="160" y="100"/>
    <text>${printObject[0]?.CustomerName}</text>
    <position x="1" y="160"/>
    <text>線種(顏色)</text>
    <position x="160" y="160"/>
    <text>${
      stringData?.filter(
        (string) => string.StringID == printObject[0]?.StringID
      )?.[0]?.StringOfficialName
    } ${
      printObject[0]?.ColourName ?? printObject[0]?.ColourID
        ? `(${printObject[0]?.ColourName ?? printObject[0]?.ColourID})`
        : ""
    }</text>
    <position x="1" y="220"/>
    <text>磅數</text>
    <position x="160" y="220"/>
    <text>${printObject[0]?.Lbs} ${
      printObject[0]?.Method === 8
        ? ""
        : printObject[0]?.Method
        ? `(${
            methodData?.[0]?.Options?.filter(
              (method) => method.Value == printObject[0]?.Method
            )?.[0]?.Text
          })`
        : ""
    }</text>
    <position x="1" y="280"/>
    <text>加購</text>
    <text width="2" height="2"/>
    <position x="160" y="280"/>
    <text>${printObject[0]?.ListOrderAddOn?.filter((add) => add.AddOnType !== 4)
      .map((item) => item.AddOnName)
      .join("/")}</text>
    <text width="1" height="1"/>
    <position x="1" y="340"/>
    <text>支數</text>
    <text width="2" height="2"/>
    <position x="160" y="340"/>
    <text>${printObject[0]?.OrderNo}-${totalCount}</text>
    <text width="1" height="1"/>
    <position x="545" y="40"/>
    <text>交拍</text>
    <text width="2" height="2"/>
    <position x="705" y="40"/>
    <text>${printObject[0]?.DeliveryDate}</text>
    <text width="1" height="1"/>
    <position x="545" y="100"/>
    <text>收拍</text>
    <position x="705" y="100"/>
    <text>${printObject[0]?.ReceivedDate}</text>
    <position x="545" y="160"/>
    <text>備註</text>
    <text width="2" height="2"/>
    <position x="705" y="160"/>
    <text>${printObject[0]?.Remark}</text>
    <text width="1" height="1"/>
    <position x="545" y="280"/>
    <text>金額</text>
    <position x="705" y="280"/>
    <text>總額 ${printObject[0]?.Cost} 已付 ${printObject[0]?.Deposit}</text>
    <position x="545" y="340"/>
    <text>應收</text>
    <text width="2" height="2"/>
    <position x="705" y="340"/>
    <text>${printObject[0]?.AmountPayable}</text>
    <feed/>
    </page>
    <cut type="feed"/>
    `;

    print[0](startEnvelope + req + endEnvelope);
  };

  return (
    <Wrapper>
      {(isLoading || connectIsLoading[0]) && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>{`${useParams()?.orderDate} 訂單`}</Title>
        </TitleWrapper>
        {/* <PrinterButtonWrapper>
          <Button
            // disabled={connection[0] === STATUS_CONNECTED}
            onClickFun={() => connect[0]()}
            text={"reconnect"}
          ></Button>
        </PrinterButtonWrapper> */}

        {recordList?.length > 0 ? (
          <OrderCardWrapper>
            {/* <TableHeader>
              {header.map((item) => (
                <TableHeaderItem
                  key={item.id}
                  style={{
                    width: `${item.width}%`,
                  }}
                >
                  {item.name}
                </TableHeaderItem>
              ))}
            </TableHeader> */}
            <OrderCardContainer>
              {recordList.map((data, index) => (
                <OrderCard key={index}>
                  <OrderCardIdWrapper>
                    <OrderCardId>
                      訂單編號 {data.OrderID}_{data.OrderNo}
                    </OrderCardId>
                    <FunctionWrapper>
                      <FunctionContainer>
                        <FunctionList
                          onClick={() =>
                            handlePrint(data.OrderNo, data.OrderID)
                          }
                          disabled={connection[0] !== STATUS_CONNECTED}
                          $status={connection[0] !== STATUS_CONNECTED}
                        >
                          <FunctionIcon>
                            <FontAwesomeIcon icon={faPrint} />
                          </FunctionIcon>
                          <FunctionText>列印</FunctionText>
                        </FunctionList>

                        <FunctionList
                          onClick={() =>
                            handleNavigate(data.OrderID, data.OrderNo)
                          }
                        >
                          <FunctionIcon>
                            <FontAwesomeIcon icon={faPencil} />
                          </FunctionIcon>
                          <FunctionText>編輯</FunctionText>
                        </FunctionList>
                      </FunctionContainer>
                    </FunctionWrapper>
                  </OrderCardIdWrapper>

                  <OrderCardContent>
                    {header.map((item) => (
                      <OrderCardItemWrapper
                        key={item.id}
                        $type={item.value === "Remark"}
                        style={{
                          gridColumn: item.value === "Remark" ? "1/3" : "",
                        }}
                      >
                        <OrderCardText>{item.name}</OrderCardText>
                        <OrderCardItem>
                          {(() => {
                            switch (item.value) {
                              case "StringID":
                                return stringData?.filter(
                                  (string) =>
                                    string.StringID == data[item.value]
                                )?.[0]?.StringOfficialName;

                              case "ColourName":
                                return data.ColourID
                                  ? colourData?.[0]?.Options?.filter(
                                      (color) => color.Value == data.ColourID
                                    )?.[0]?.Text
                                  : data.ColourName;

                              case "Method":
                                return methodData?.[0]?.Options?.filter(
                                  (method) => method.Value == data[item.value]
                                )?.[0]?.Text;

                              case "Adjudicator":
                                return staffData?.[0]?.Options?.filter(
                                  (staff) => staff.Value == data[item.value]
                                )?.[0]?.Text;

                              case "PayStatus":
                                return data?.PayStatus == 0
                                  ? "尚未付款"
                                  : "已付款";

                              case "ListOrderAddOn":
                                return data.ListOrderAddOn?.filter(
                                  (add) => add.AddOnType !== 4
                                )
                                  .map((item) => item.AddOnName)
                                  .join(" / ");

                              case "Other":
                                return data.ListOrderAddOn?.filter(
                                  (add) => add.AddOnType === 4
                                ).length > 0
                                  ? `${
                                      data.ListOrderAddOn?.filter(
                                        (add) => add.AddOnType === 4
                                      )[0]?.AddOnName ?? "無"
                                    } /
                                        ${
                                          data.ListOrderAddOn?.filter(
                                            (add) => add.AddOnType === 4
                                          )[0]?.AddOnCost
                                        }`
                                  : "";

                              default:
                                return data[item.value];
                            }
                          })()}
                        </OrderCardItem>
                      </OrderCardItemWrapper>
                    ))}
                  </OrderCardContent>
                  <OrderCost>${data.Cost}</OrderCost>
                </OrderCard>
              ))}
            </OrderCardContainer>
          </OrderCardWrapper>
        ) : (
          <NoData>
            <NoDataIcon>
              <FontAwesomeIcon icon={faFileCircleExclamation} />
            </NoDataIcon>
            <NoDataText>查無資料</NoDataText>
          </NoData>
        )}
      </Container>
    </Wrapper>
  );
};

export default OrderDateRecordPage;
