import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "../services/loginApi";
import loginReducer from "../slices/loginSlice";
import { stringingApi } from "../services/stringingApi";
import { systemApi } from "../services/systemApi";
import systemReducer from "../slices/systemSlice";
import { authApi } from "../services/authApi";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    login: loginReducer,
    [stringingApi.reducerPath]: stringingApi.reducer,
    [systemApi.reducerPath]: systemApi.reducer,
    system: systemReducer,
    [authApi.reducerPath]: authApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      loginApi.middleware,
      stringingApi.middleware,
      systemApi.middleware,
      authApi.middleware,
    ]),
});

export default store;
