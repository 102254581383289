import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";
import { getAuthFun } from "../../utils";
import Loading from "../components/Loading";

import MethodTab from "./Tab/MethodTab";
import StringTab from "./Tab/StringTab";
import GrommetTab from "./Tab/GrommetTab";
import UrgentTab from "./Tab/UrgentTab";
import LogoTab from "./Tab/LogoTab";
import StaffTab from "./Tab/StaffTab";

import SystemModalLayout from "../components/SystemModalLayout";
import { useGetParamMutation } from "../../services/systemApi";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 50px;

  ${RWD_LG(css`
    padding-bottom: 150px;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  padding: 20px 0 50px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const TabWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 10px;
  overflow: auto;
`;

const CreateButtonWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 10px;
  width: 120px;
  cursor: pointer;
  background: #16161a;
  color: #fcfcfc;
  border: 1px solid #16161a;
`;

const Tab = styled.div`
  padding: 10px 30px;
  border: 1px solid;
  /* border-bottom: 0px; */
  /* margin-right: 10px; */
  border-radius: 40px;

  white-space: nowrap;
  color: ${({ $active }) => ($active ? "#16161a" : "F5F5F5")};
  border-color: ${({ $active }) => ($active ? " #16161a" : "#F5F5F5")};
  cursor: pointer;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding: 10px 20px;
  `)};

  ${RWD_SM(css`
    padding: 5px 15px;
  `)};

  /* background: #fafafa; */
`;

const TabText = styled.div``;

const TabContentContainer = styled.div`
  /* border: 1px solid #16161a; */
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

const SystemPage = () => {
  const navigate = useNavigate();
  const authData = getAuthFun();
  // 權限判斷
  // 系統維護 FunctionId 4
  const [haveAuth, setHaveAuth] = useState(
    authData.filter((data) => data.FunctionId === 4)[0].AuthSeq > 0
  );

  useEffect(() => {
    if (!haveAuth) {
      navigate("/NoAuth");
    }
  }, []);

  const [activeTab, setActiveTab] = useState(1);
  const [tabList, setTabList] = useState([
    {
      id: 1,
      name: "線種",
    },
    {
      id: 2,
      name: "穿線法",
    },
    {
      id: 3,
      name: "孔丁",
    },
    {
      id: 4,
      name: "案件",
    },
    {
      id: 5,
      name: "Logo",
    },
    {
      id: 6,
      name: "員工",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [stringModalIsOpen, setStringModalIsOpen] = useState(false);
  const [staffModalIsOpen, setStaffModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalType, setModalType] = useState("");

  const [paramData, setParamData] = useState([]);

  const [_getParam, { data, isSuccess, isError, error, isLoading }] =
    useGetParamMutation();

  useEffect(() => {
    if (activeTab > 1) {
      const param = {
        Type: activeTab - 2,
      };

      _getParam(param);
    }
  }, [activeTab]);

  useEffect(() => {
    if (isSuccess) {
      setParamData(data.ListParam);
    }
  }, [data, isError]);

  const handleCreate = () => {
    setModalType("create");
    if (activeTab > 1 && activeTab !== 6) {
      setIsOpen(true);
    } else {
      if (activeTab === 1) setStringModalIsOpen(true);
      if (activeTab === 6) setStaffModalIsOpen(true);
    }
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>系統維護</Title>
        </TitleWrapper>
        <TabWrapper>
          <TabContainer>
            {tabList.map((data) => (
              <Tab
                key={data.id}
                $active={activeTab === data.id}
                onClick={() => setActiveTab(data.id)}
              >
                <TabText>{data.name}</TabText>
              </Tab>
            ))}
          </TabContainer>
          <CreateButtonWrapper>
            <CreateButton onClick={handleCreate}>
              <FontAwesomeIcon icon={faPlus} />
              建立
              {(() => {
                switch (activeTab) {
                  case 1:
                    return "線種";
                  case 2:
                    return "穿線法";
                  case 3:
                    return "孔丁";
                  case 4:
                    return "案件";
                  case 5:
                    return "Logo";
                  case 6:
                    return "員工";
                }
              })()}
            </CreateButton>
          </CreateButtonWrapper>

          <TabContentContainer>
            {activeTab == 1 && (
              <StringTab
                setStringModalIsOpen={setStringModalIsOpen}
                stringModalIsOpen={stringModalIsOpen}
                modalType={modalType}
                setModalType={setModalType}
              />
            )}
            {activeTab == 2 && (
              <MethodTab
                setIsOpen={setIsOpen}
                setModalData={setModalData}
                paramData={paramData}
                setModalType={setModalType}
              />
            )}
            {activeTab == 3 && (
              <GrommetTab
                setIsOpen={setIsOpen}
                setModalData={setModalData}
                paramData={paramData}
                setModalType={setModalType}
              />
            )}
            {activeTab == 4 && (
              <UrgentTab
                setIsOpen={setIsOpen}
                setModalData={setModalData}
                paramData={paramData}
                setModalType={setModalType}
              />
            )}
            {activeTab == 5 && (
              <LogoTab
                setIsOpen={setIsOpen}
                setModalData={setModalData}
                paramData={paramData}
                setModalType={setModalType}
              />
            )}
            {activeTab == 6 && (
              <StaffTab
                setStaffModalIsOpen={setStaffModalIsOpen}
                staffModalIsOpen={staffModalIsOpen}
                modalType={modalType}
                setModalType={setModalType}
              />
            )}
          </TabContentContainer>
        </TabWrapper>
      </Container>
      {isOpen && (
        <SystemModalLayout
          setIsOpen={setIsOpen}
          modalData={modalData}
          activeTab={activeTab}
          reFetch={_getParam}
          modalType={modalType}
          setModalType={setModalType}
        />
      )}
    </Wrapper>
  );
};

export default SystemPage;
