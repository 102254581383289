import Cookies from "js-cookie";
import moment from "moment";

const TOKEN_NAME = "stringingSystemToken";
const USER_NAME = "stringingSystemUser";
const AUTH_FUN = "stringingSystemAuthFun";

export const setAuthToken = (token, user) => {
  Cookies.set(TOKEN_NAME, token, { expires: 365 });
  Cookies.set(USER_NAME, user, { expires: 365 });
};

export const getAuthToken = () => {
  return {
    token: Cookies.get(TOKEN_NAME),
    user: Cookies.get(USER_NAME),
  };
};

export const clearAuthToken = () => {
  Cookies.remove(TOKEN_NAME);
  Cookies.remove(USER_NAME);
};

export const setAuthFun = (fun) => {
  localStorage.setItem(AUTH_FUN, fun);
};

export const getAuthFun = () => {
  return JSON.parse(localStorage.getItem(AUTH_FUN));
};

export const clearAuthFun = () => {
  localStorage.removeItem(AUTH_FUN);
};

export const formatDate = (value) => {
  const dateToValid = moment(value, "YYYY-MM-DD HH:mm:ss").toDate();
  const year = dateToValid.getFullYear().toString();
  const month = (dateToValid.getMonth() + 1).toString().padStart(2, "0");
  const date = dateToValid.getDate().toString().padStart(2, "0");
  const hour = dateToValid.getHours().toString().padStart(2, "0");
  const minute = dateToValid.getMinutes().toString().padStart(2, "0");
  const second = dateToValid.getSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
};

export const deliveryFormatDate = (value) => {
  const dateToValid = moment(value, "YYYY-MM-DD").toDate();
  const year = dateToValid.getFullYear().toString();
  const month = (dateToValid.getMonth() + 1).toString().padStart(2, "0");
  const date = dateToValid.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${date} `;
};

export const filteredObject = (objectArr) => {
  return objectArr.map((data) => {
    const { id, isOpen, Colour, ...myUpdatedObject } = data;
    return myUpdatedObject;
  });
};

export const mobileFormat = (value) => {
  const regex = new RegExp(/^09\d{2}-?\d{3}-?\d{3}$/);
  const sameNumberRegex = new RegExp(/^(\d)\1{9}$/);
  if (!regex.test(value)) {
    if (!sameNumberRegex.test(value)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const accountCheck = (value) => {
  const regex = new RegExp(/^[a-zA-Z0-9]{6,12}$/);
  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const passwordCheck = (value) => {
  const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/);
  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const valueCheck = (value, type) => {
  if (value !== "" && value !== null) {
    return { type: type, status: true };
  }
  return { type: type, status: false };
};
