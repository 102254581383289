import { styled, css } from "styled-components";
import { useEffect, useState } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Input from "../../global/Input";
import Calendar from "../../global/Calendar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../global/Checkbox";
import Button from "../../global/Button";
import { formatDate } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  position: relative;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.302vw, 20px), 25px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;

  ${RWD_SM(css`
    font-size: clamp(18px, 4.65vw, 20px);
    padding-bottom: 15px;
  `)};
`;

const CloseIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #16161a;
  color: #fcfcfc;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px 5vw;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 30px;
`;

const StringCostModalLayout = ({
  listStringPriceRecords,
  setListStringPriceRecords,
  setCostModalIsOpen,
  costModalType,
  costModalData,
}) => {
  console.log(costModalType);
  console.log(listStringPriceRecords);
  console.log(costModalData);

  const template = {
    StartDate: "", // 起始日
    SellingPrice: "", // 穿線價
    MarkedPrice: "", // 官方定價
    SinglePurchasePrice: "", // 售價(單買線)
    Cost: "", // 成本
    Enabled: true,
  };

  const [startDate, setStartDate] = useState(new Date());
  const [sellingPrice, setSellingPrice] = useState("");
  const [markedPrice, setMarkedPrice] = useState("");
  const [singlePurchasePrice, setSinglePurchasePrice] = useState("");
  const [cost, setCost] = useState("");
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (costModalData.length > 0 && costModalType !== "create") {
      setStartDate(new Date(costModalData[0]?.StartDate));
      setSellingPrice(costModalData[0]?.SellingPrice);
      setMarkedPrice(costModalData[0]?.MarkedPrice);
      setSinglePurchasePrice(costModalData[0]?.SinglePurchasePrice);
      setCost(costModalData[0]?.Cost);
      setEnabled(costModalData[0]?.Enabled ? true : false);
    }
  }, [costModalData]);

  const isValid = startDate !== "" && sellingPrice !== "";

  const handleCloseFun = () => {
    setCostModalIsOpen(false);
  };

  const handleCreate = () => {
    setListStringPriceRecords((listStringPriceRecords) => [
      ...listStringPriceRecords,
      {
        StartDate: formatDate(startDate),
        SellingPrice: Number(sellingPrice),
        MarkedPrice: Number(markedPrice),
        SinglePurchasePrice: Number(singlePurchasePrice),
        Cost: Number(cost),
        Enabled: enabled ? 1 : 0,
      },
    ]);
    handleCloseFun();
  };

  const handleEdit = (id) => {
    console.log("維護");
    setListStringPriceRecords(
      listStringPriceRecords.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            StartDate: formatDate(startDate),
            SellingPrice: sellingPrice,
            MarkedPrice: markedPrice,
            SinglePurchasePrice: singlePurchasePrice,
            Cost: cost,
            Enabled: enabled ? 1 : 0,
          };
        }

        return item;
      })
    );
    handleCloseFun();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setSellingPrice("");
    setMarkedPrice("");
    setSinglePurchasePrice("");
    setCost("");
    setEnabled(true);
  };

  return (
    <Wrapper>
      <Container>
        <CloseIcon onClick={handleCloseFun}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseIcon>
        <Title>
          價格
          {costModalType === "create" ? "建立" : "維護"}
        </Title>
        <FormWrapper>
          <Calendar
            title={"起始日"}
            selectDate={startDate}
            onChangeFun={setStartDate}
          />
          <Input
            title={"穿線價"}
            onChangeFun={setSellingPrice}
            value={sellingPrice}
          />
          <Input
            title={"官方定價"}
            onChangeFun={setMarkedPrice}
            value={markedPrice}
          />
          <Input
            title={"售價(單買線)"}
            onChangeFun={setSinglePurchasePrice}
            value={singlePurchasePrice}
          />
          <Input title={"成本"} onChangeFun={setCost} value={cost} />

          <Checkbox title={"啟用"} onChangeFun={setEnabled} value={enabled} />
        </FormWrapper>
        <ButtonWrapper>
          {costModalType === "create" && (
            <Button text={"清除資料"} onClickFun={handleClear} />
          )}
          {costModalType === "create" ? (
            <Button
              text={"新增"}
              disabled={!isValid}
              onClickFun={handleCreate}
            />
          ) : (
            <Button
              text={"更新"}
              disabled={!isValid}
              onClickFun={() => handleEdit(costModalData[0]?.id)}
            />
          )}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default StringCostModalLayout;
