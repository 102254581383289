import { styled, ThemeProvider, css } from "styled-components";
import { Outlet } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

import Menu from "./components/Menu";
import Header from "./components/Header";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

import {
  useGetDropDownListMutation,
  useGetStringsQuery,
  useGetStaffQuery,
} from "../services/systemApi";
import { useDispatch } from "react-redux";
import { getDropDownList, getStaff, getStrings } from "../slices/systemSlice";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  min-height: 100vh;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
`;

const Container = styled.div`
  width: calc(100% - 200px);
  margin-left: auto;
  margin-top: 60px;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const Layout = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  // 下拉選單
  const [_getDropDown, { data, isError, error, isSuccess, refetch }] =
    useGetDropDownListMutation();

  useEffect(() => {
    _getDropDown({ Type: 0 });
  }, []);

  const [printerIpAddress, setPrinterIpAddress] = useState("192.168.50.111");
  const [printerPort, setPrinterPort] = useState("8043");
  const [connectionStatus, setConnectionStatus] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [orderIsOpen, setOrderIsOpen] = useState(false);
  const [clearCustData, setClearCustData] = useState(false);

  const printer = useRef();

  const connect = () => {
    setConnectionStatus("connecting");
    setConnecting(true);
    // setLoading(true);

    if (!printerIpAddress) {
      setConnectionStatus("Type the printer IP address");
      return;
    }
    if (!printerPort) {
      setConnectionStatus("Type the printer port");
      return;
    }

    const ePosDev = new window.epson.ePOSDevice();
    // ePosDevice.current = ePosDev;

    console.log(ePosDev);

    ePosDev.connect(printerIpAddress, printerPort, (data) => {
      if (data === "OK" || data === "SSL_CONNECT_OK") {
        setConnecting(false);
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: true, buffer: false },
          (devobj, retcode) => {
            if (retcode === "OK") {
              printer.current = devobj;
              setConnectionStatus("connected");
            } else {
              throw retcode;
            }
          }
        );
      } else {
        setConnecting(false);
        setConnectionStatus("disconnected");
        MySwal.fire({
          title: "連線失敗",
          text: data,
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
          allowOutsideClick: false,
        });
        throw data;
      }
    });
  };

  const print = (printData) => {
    const url = `https://${printerIpAddress}/cgi-bin/epos/service.cgi`;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml; charset=utf-8");
    xhr.setRequestHeader("If-Modified-Since", "Thu, 01 Jan 1970 00:00:00 GMT");
    xhr.setRequestHeader("SOAPAction", '""');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // Parse response document
        if (xhr.status == 200) {
          // alert(xhr.responseXML.getElementsByTagName('response')[0].getAttribute('success'));
        } else {
          // alert("Network error occured.");
          MySwal.fire({
            title: "連線失敗",
            icon: "error",
            confirmButtonText: "確認",
            confirmButtonColor: "#16161a",
            allowOutsideClick: false,
          });
        }
      }
    };
    xhr.send(printData);
  };

  // useEffect(() => {
  //   connect();
  // }, []);

  const {
    data: strings,
    isError: stringsIsError,
    error: stringsError,
    isSuccess: stringsIsSuccess,
    refetch: stringsRefetch,
    isFetching: stringsIsFetching,
  } = useGetStringsQuery();

  const {
    data: staff,
    isError: staffIsError,
    error: staffError,
    isSuccess: staffIsSuccess,
    refetch: staffRefetch,
    isFetching: staffIsFetching,
  } = useGetStaffQuery;

  useEffect(() => {
    stringsRefetch();
  }, []);

  useEffect(() => {
    if (stringsIsSuccess) {
      dispatch(getStrings(strings));
    }
  }, [strings, stringsIsError, stringsIsFetching]);

  useEffect(() => {
    if (staffIsSuccess) {
      dispatch(getStaff(staff));
    }
  }, [staff, staffIsError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(getDropDownList(data));
    }
  }, [data, isError]);

  const handleAgreement = () => {
    MySwal.fire({
      title: "同意聲明",
      icon: "warning",
      html: `
      <ul>
          <li style="text-align:left">本店不提供寄放拍袋，絨布袋服務</li>
          <li style="text-align:left">
            無烤漆，毛拍，超過建議磅數，拍札受損，掉漆之球拍，穿線斷裂不負責
          </li>
          <li style="text-align:left">
            本店不會故意使拍子烤漆受損，亦不負責所有拍子烤漆塗裝之問題，如很在意請三思再進行穿線動作，所有烤漆問題請自行處理
          </li>
          <li style="text-align:left">取拍時請當面詳細檢查，離開後不負任何責任</li>
          <li style="text-align:left">當日急件加300元急件費用，普通急件200元</li>
          <li style="text-align:left">穿線一律現金付款</li>
          <li style="text-align:left">非約定日期提前取拍，一律算急件費用</li>
          <li style="text-align:left">同意於本店穿線，即代表同意所有規定及事項</li>
        </ul>
    `,
      confirmButtonText: "同意",
      confirmButtonColor: "#16161a",
      showCancelButton: true,
      cancelButtonText: "取消",
      cancelButtonColor: "#ccc",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setOrderIsOpen(true);
        setClearCustData(true);
      }
    });
  };

  return (
    <Wrapper>
      <Header
        connectionStatus={connectionStatus}
        reconnect={connect}
        connectIsLoading={connecting}
      />
      <Menu handleAgreement={handleAgreement} />
      <Container>
        <Outlet
          context={{
            connection: [connectionStatus, setConnectionStatus],
            ip: [printerIpAddress, setPrinterIpAddress],
            port: [printerPort, setPrinterPort],
            connect: [connect],
            connectIsLoading: [connecting],
            print: [print],
            agreement: [handleAgreement, orderIsOpen, setOrderIsOpen],
            custData: [clearCustData, setClearCustData],
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default Layout;
