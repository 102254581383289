import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "./query/defaultBaseQuery";
import * as qs from "qs";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getFunctionList: builder.mutation({
      query: (credentials) => ({
        url: `Auth/GetFunctionList`,
        method: "POST",
        body: credentials,
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getUserAuth: builder.mutation({
      query: ({ UserNo }) => ({
        url: `Auth/GetUserAuth`,
        method: "POST",
        body: qs.stringify({
          UserNo,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getRoleAuth: builder.mutation({
      query: ({ RoleID }) => ({
        url: `Auth/GetRoleAuth`,
        method: "POST",
        body: qs.stringify({
          RoleID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
  }),
});

export const {
  useGetFunctionListMutation,
  useGetUserAuthMutation,
  useGetRoleAuthMutation,
} = authApi;
