import { styled, css } from "styled-components";
import { useEffect, useState } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import Input from "../../global/Input";
import Textarea from "../../global/Textarea";
import Button from "../../global/Button";
import Select from "../../global/Select";
import Checkbox from "../../global/Checkbox";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  useUpdateStaffMutation,
  useCreateStaffMutation,
} from "../../services/systemApi";

import { accountCheck, passwordCheck, mobileFormat } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  position: relative;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;

  ${RWD_SM(css`
    padding-right: 10px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;

  ${RWD_SM(css`
    font-size: clamp(18px, 4.65vw, 20px);
    padding-bottom: 15px;
  `)};
`;

const CloseIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #16161a;
  color: #fcfcfc;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px 5vw;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 30px;
`;

const StaffModalLayout = ({
  modalData,
  modalType,
  setIsOpen,
  setModalType,
  staffList,
}) => {
  const MySwal = withReactContent(Swal);

  const staffAccount = staffList.map((data) => data.UserNo);

  const [userNo, setUserNo] = useState(""); // 必填 6-12英數字
  const [userName, setUserName] = useState("");
  const [nickName, setNiciName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); // 必填 6-12英數字 區分大小寫
  const [roleID, setRoleID] = useState(""); // 必填
  const [enabled, setEnabled] = useState(true); // 必填
  const [remark, setRemark] = useState("");

  const [checkPassword, setCheckPassword] = useState("");

  useEffect(() => {
    if (modalData.length > 0 && modalType !== "create") {
      setUserNo(modalData[0]?.UserNo);
      setUserName(modalData[0]?.UserName);
      setNiciName(modalData[0]?.NickName);
      setPhone(modalData[0]?.Phone);
      setRoleID(modalData[0]?.RoleId);
      setRemark(modalData[0]?.Remark);
    }
  }, [modalData]);

  const createIsValid =
    userName !== "" &&
    userNo !== "" &&
    roleID !== "" &&
    password !== "" &&
    checkPassword !== "";

  const editIsValid = userName !== "" && userNo !== "" && roleID !== "";

  const [
    _update,
    {
      data: updateData,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      error: updateError,
    },
  ] = useUpdateStaffMutation();

  const [
    _create,
    {
      data: createData,
      isError: createIsError,
      isSuccess: createIsSuccess,
      error: createError,
    },
  ] = useCreateStaffMutation();

  useEffect(() => {
    if (createIsSuccess) {
      MySwal.fire({
        title: "建立成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleCloseFun();
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (updateIsError) {
      MySwal.fire({
        title: "建立失敗",
        text: createError?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [createData, createIsError]);

  useEffect(() => {
    if (updateIsSuccess) {
      MySwal.fire({
        title: "更新成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (updateIsError) {
      MySwal.fire({
        title: "更新失敗",
        text: updateError?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [updateData, updateIsError]);

  const handleCloseFun = () => {
    setIsOpen(false);
    setModalType("");
  };

  const handleCreate = () => {
    const data = {
      UserNo: userNo,
      UserName: userName,
      NickName: nickName,
      Phone: phone,
      Password: password,
      RoleID: roleID,
      Remark: remark,
    };

    if (!accountCheck(userNo)) {
      MySwal.fire({
        title: "帳號格式錯誤",
        text: "帳號需為6-12位英數字",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (staffAccount.includes(userNo)) {
      MySwal.fire({
        title: "此帳號已有員工使用",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (!passwordCheck(password)) {
      MySwal.fire({
        title: "密碼格式錯誤",
        text: "密碼需為6-12位英數字，並區分大小寫",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (checkPassword != password) {
      MySwal.fire({
        title: "兩次密碼輸入不同",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (password === userNo) {
      MySwal.fire({
        title: "帳號和密碼不可相同",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (phone !== "" && !mobileFormat(phone)) {
      MySwal.fire({
        title: "手機格式錯誤",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else {
      MySwal.fire({
        title: "帳號設定後不可變更",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        showCancelButton: true,
        cancelButtonText: "取消",
        cancelButtonColor: "#ccc",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          _create(data);
        } else {
          return;
        }
      });
      // _create(data);
    }
  };

  const handleUpdate = () => {
    const data = {
      UserNo: userNo,
      UserName: userName,
      NickName: nickName,
      Phone: phone,
      Password: password,
      RoleID: roleID,
      Remark: remark,
      UserID: modalData[0]?.UserID,
      Enabled: enabled ? 1 : 0,
    };

    if (!accountCheck(userNo)) {
      MySwal.fire({
        title: "帳號格式錯誤",
        text: "帳號需為6-12位英數字",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (password !== "" && !passwordCheck(password)) {
      MySwal.fire({
        title: "密碼格式錯誤",
        text: "密碼需為6-12位英數字，並區分大小寫",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (password !== "" && checkPassword === "") {
      MySwal.fire({
        title: "再次輸入密碼不得為空",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (checkPassword != password) {
      MySwal.fire({
        title: "兩次密碼輸入不同",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (password === userNo) {
      MySwal.fire({
        title: "帳號和密碼不可相同",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else if (phone !== "" && !mobileFormat(phone)) {
      MySwal.fire({
        title: "手機格式錯誤",
        icon: "warning",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      });
    } else {
      _update(data);
    }
  };

  const handleClear = () => {
    setUserNo("");
    setUserName("");
    setNiciName("");
    setPhone("");
    setPassword("");
    setRoleID("");
    setEnabled(true);
    setRemark("");
    setCheckPassword("");
  };

  return (
    <Wrapper>
      <Container>
        <CloseIcon onClick={handleCloseFun}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseIcon>
        <Title>
          員工
          {modalType === "create" ? "建立" : "維護"}
        </Title>
        <ScrollContainer>
          <FormWrapper>
            <Input
              title={"名稱"}
              onChangeFun={setUserName}
              value={userName}
              required={true}
            />
            <Input title={"暱稱"} onChangeFun={setNiciName} value={nickName} />
            <Input title={"手機號碼"} onChangeFun={setPhone} value={phone} />
            <Select
              title={"權限等級"}
              group={"Roles"}
              onChangeFun={setRoleID}
              value={roleID}
              required={true}
            />
            <Input
              title={"帳號"}
              onChangeFun={setUserNo}
              value={userNo}
              required={true}
              disabled={modalType === "edit"}
              placeholder={"6-12位英數字"}
            />
            <Input
              title={"密碼"}
              onChangeFun={setPassword}
              value={password}
              required={modalType === "create" ? true : false}
              type={"password"}
              placeholder={"6-12位英數字密碼，請區分大小寫"}
            />
            {modalType === "edit" && (
              <Checkbox
                title={"在職"}
                value={enabled}
                onChangeFun={setEnabled}
              />
            )}
            <Input
              title={"再次輸入密碼"}
              onChangeFun={setCheckPassword}
              value={checkPassword}
              required={modalType === "create" ? true : false}
              type={"password"}
            />
          </FormWrapper>
          <Textarea title={"備註"} onChangeFun={setRemark} value={remark} />
          <ButtonWrapper>
            {modalType === "create" && (
              <Button text={"清除資料"} onClickFun={handleClear} />
            )}

            {modalType === "create" ? (
              <Button
                text={"建立"}
                onClickFun={handleCreate}
                disabled={!createIsValid}
              />
            ) : (
              <Button
                text={"更新"}
                onClickFun={handleUpdate}
                disabled={!editIsValid}
              />
            )}
          </ButtonWrapper>
        </ScrollContainer>
      </Container>
    </Wrapper>
  );
};

export default StaffModalLayout;
