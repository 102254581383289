import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { getAuthFun } from "../../utils";

import Input from "../../global/Input";
import Select from "../../global/Select";
import Calendar from "../../global/Calendar";
import Checkbox from "../../global/Checkbox";
import Textarea from "../../global/Textarea";
import Button from "../../global/Button";
import CombineInput from "../../global/CombineInput";
import SearchInput from "../../global/SearchInput";

import { useState, useEffect } from "react";

import {
  useCreateStringingRecordMutation,
  useGetOrderOfTheDayMutation,
} from "../../services/stringingApi";
import { formatDate, filteredObject } from "../../utils";
import { useSelector } from "react-redux";
import { selectDropDown } from "../../slices/systemSlice";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import OrderLayout from "../components/OrderLayout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCirclePlus,
  faTrash,
  faPlus,
  faCopy,
  faEllipsis,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

const TableWrapper = styled.div`
  padding: 20px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 30px;
`;

const TableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableTitle = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  text-align: left;
  padding: 30px 0;

  ${RWD_SM(css`
    padding: 20px 0;
  `)};
`;

const CreateOrder = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 10px;
  width: 120px;
  cursor: pointer;
  background: #16161a;
  color: #fcfcfc;
  border: 1px solid #16161a;
`;

const CreateOrderIcon = styled.div``;

const CreateOrderText = styled.div`
  padding-left: 10px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const TableHeaderItem = styled.div`
  color: #b3bfbf;
  padding: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 50px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const Order = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #16161a;
`;

const IdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px 5px 0;
  /* background: #fafafa; */
`;

const Id = styled.div`
  padding: 10px 10px;
  font-weight: 500;
  /* border-bottom: 1px solid #ccc; */
`;

const DeleteIcon = styled.div`
  display: flex;
  width: 40px;
  align-self: stretch;
  /* width: 30px;
  height: 30px; */
  align-items: center;
  justify-content: center;
  color: #fcfcfc;
  background: #16161a;
  cursor: pointer;
  position: relative;
`;

const Amount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
`;

const OrderCostWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${RWD_SM(css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-column: 1 / 1;
    grid-template-columns: 30% auto; */
    /* grid-template-rows: repeat(1, 1fr); */
  `)};
`;

const OrderSimpleWrapper = styled.div`
  display: grid;
  grid-template-columns: 45% auto;

  :last-child {
    border-bottom: 1px solid transparent;
  }

  :nth-child(1) {
    border-bottom: 1px solid transparent;
  }

  ${RWD_SM(css`
    grid-template-columns: 30% auto;
    border-bottom: 1px solid #ccc;

    :nth-child(1) {
      border-bottom: 1px solid transparent;
    }
    :last-child {
      border-bottom: 1px solid transparent;
    }
  `)};
`;

const OtherWrapper = styled.div`
  display: grid;
  grid-template-columns: 15% auto;

  ${RWD_SM(css`
    grid-template-columns: 30% auto;
  `)};
`;

const OrderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${RWD_SM(css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-column: 1 / 1;
    grid-template-columns: 30% auto; */
    /* grid-template-rows: repeat(1, 1fr); */
  `)};
`;

const OrderItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  /* border: 1px solid #16161a; */
`;

const OrderItemTitle = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
`;

const OrderItem = styled.div`
  border-bottom: 1px solid #ccc;
  /* border: 1px solid red;
  
  align-items: center; */
  /* padding: 0 20px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  /* border: 1px solid red; */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 100px 0;
  margin-top: auto;
  gap: 20px;

  ${RWD_LG(css`
    padding-bottom: 150px;
  `)};
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  color: #ccc;
  border: 1px solid #ccc;
  border-style: dashed;
  margin-bottom: 100px;
`;

const NoDataIcon = styled.div`
  font-size: clamp(35px, 4.1666vw, 80px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NoDataText = styled.div`
  padding-top: 20px;
`;

const FunctionWrapper = styled.div`
  /* position: absolute; */
  /* top: 100%; */
  /* right: 0; */

  /* display: ${({ $status }) => ($status ? "flex" : "none")}; */
  display: flex;
  padding-right: 5px;
`;

const FunctionContainer = styled.div`
  width: 100%;
  /* border: 1px solid #16161a; */
  display: flex;
`;

const FunctionList = styled.div`
  background-color: antiquewhite;
  padding: 5px 10px;
  color: #16161a;
  background: #fafafa;
  border: 1px solid #16161a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }
`;

const FunctionIcon = styled.div``;

const FunctionText = styled.div`
  padding-left: 10px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const OrderCreationPage = () => {
  const { connection, print, agreement, custData } = useOutletContext();

  const navigate = useNavigate();
  const authData = getAuthFun();
  // 權限判斷
  // 訂單建立 FunctionId 2
  const [haveAuth, setHaveAuth] = useState(
    authData.filter((data) => data.FunctionId === 2)[0].AuthSeq > 0
  );

  useEffect(() => {
    if (!haveAuth) {
      navigate("/NoAuth");
    } else {
      // 打開同意聲明燈箱
      agreement[0]();
    }
  }, []);

  const grommetData = useSelector(selectDropDown).List?.Grommet;
  const stringData = useSelector(selectDropDown)?.String;
  const colourData = useSelector(selectDropDown).List?.Colour;
  const methodData = useSelector(selectDropDown).List?.StringMethod;
  const staffData = useSelector(selectDropDown).List?.Staff;

  const dropdownData = useSelector(selectDropDown).List;

  const [
    _getRecordData,
    {
      data: recordData,
      isSuccess: recordIsSuccess,
      isError: recordIsError,
      error: recordError,
      isLoading: recordIsLoading,
    },
  ] = useGetOrderOfTheDayMutation();

  useEffect(() => {
    if (recordIsSuccess) {
      if (recordData.ListGetOrderOfTheDay.length > 0) {
        const num = String(
          Number(
            recordData.ListGetOrderOfTheDay[
              recordData.ListGetOrderOfTheDay.length - 1
            ]?.OrderID.substr(-3, 3)
          )
        ).padStart(3, "0");
        const orderID = `${formatDate(new Date()).split("-").join("")}${num}`;
        handleCreatePrintObject(orderID);
      } else {
        const orderID = `${formatDate(new Date()).split("-").join("")}001`;
        handleCreatePrintObject(orderID);
      }
    }

    if (recordIsError) {
      console.log(recordError?.data?.Message);
    }
  }, [recordData, recordIsError]);

  const [_create, { isSuccess, data, isError, error }] =
    useCreateStringingRecordMutation();

  const MySwal = withReactContent(Swal);

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [stringID, setStringID] = useState("");
  const [colour, setColour] = useState("");
  const [grommetID, setGrommetID] = useState("");
  const [method, setMethod] = useState("");
  const [payStatus, setPayStatus] = useState(false);
  const [urgent, setUrgent] = useState("");
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState("");
  const [adjudicator, setAdjudicator] = useState("");
  const [cost, setCost] = useState(0);
  const [remark, setRemark] = useState("");

  const [firstLbsValue, setFirstLbsValue] = useState("");
  const [secondLbsValue, setSecondLbsValue] = useState("");

  const [orderList, setOrderList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [grommentCost, setGrommetCost] = useState(0);
  const [stringCost, setStringCost] = useState(0);
  const [stringName, setStringName] = useState("");
  const [selectableColor, setSelectableColor] = useState([]);

  // const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("create");

  const [printStatus, setPrintStatus] = useState(false);

  // 刪除訂單
  const handleDelete = (id) => {
    MySwal.fire({
      title: "確定刪除",
      icon: "warning",
      confirmButtonText: "確定",
      confirmButtonColor: "#16161a",
      showCancelButton: true,
      cancelButtonText: "取消",
      cancelButtonColor: "#ccc",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setOrderList(
          orderList
            .filter((data) => data.id !== id)
            .map((item, index) => {
              return {
                ...item,
                id: index + 1,
              };
            })
        );
      } else {
        return;
      }
    });
  };

  // 建立訂單
  const handleCreate = () => {
    // 判斷為使用者自行輸入的顏色 或是資料庫內的顏色
    const filterData = orderList.map((data) => {
      if (typeof data.Colour === "string") {
        return {
          ...data,
          ColourName: data.Colour,
        };
      } else {
        return {
          ...data,
          ColourID: data.Colour,
        };
      }
    });
    const data = {
      ListOrderCreate: filteredObject(filterData),
    };
    _create(data);
  };

  // 建立後列印訂單
  const handleCreateAndPrint = () => {
    setPrintStatus(true);
    handleCreate();
  };

  // 列印物件
  const handleCreatePrintObject = (orderNum) => {
    const filterData = filteredObject(
      orderList.map((data) => {
        if (typeof data.Colour === "string") {
          return {
            ...data,
            ColourName: data.Colour,
          };
        } else {
          return {
            ...data,
            ColourID: data.Colour,
          };
        }
      })
    );

    const startEnvelope = `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header>
    <parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    <devid>local_printer</devid>
    <timeout>10000</timeout>
    </parameter>
    </s:Header>
    <s:Body>
    <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
    `;

    const endEnvelope = `
    </epos-print>
    </s:Body>
    </s:Envelope>
    `;

    const printData = filterData.map((data, index) => {
      const req = `
      <page>
      <area x="0" y="0" width="375" height="1090"/>
      <text lang="zh-tw"/>
      <text smooth="true"/>
      <direction dir="top_to_bottom"/>
      <text font="font_a"/>
      <text align="center"/>
      <feed/>
      <text align="left"/>
      <position x="1" y="40"/>
      <text>電話</text>
      <text width="2" height="2"/>
      <position x="160" y="40"/>
      <text>${data?.CustomerID}</text>
      <text width="1" height="1"/>
      <position x="1" y="100"/>
      <text>姓名</text>
      <position x="160" y="100"/>
      <text>${data?.CustomerName}</text>
      <position x="1" y="160"/>
      <text>線種(顏色)</text>
      <position x="160" y="160"/>
      <text>${
        stringData?.filter((string) => string.StringID == data?.StringID)?.[0]
          ?.StringOfficialName
      }${
        data?.ColourName ?? data?.ColourID
          ? `(${data?.ColourName ?? data?.ColourID})`
          : ""
      }</text>
      <position x="1" y="220"/>
      <text>磅數</text>
      <position x="160" y="220"/>
      <text>${data?.Lbs} ${
        data?.Method === 8
          ? ""
          : data?.Method
          ? `(${
              methodData?.[0]?.Options?.filter(
                (method) => method.Value == data?.Method
              )?.[0]?.Text
            })`
          : ""
      }</text>
      <position x="1" y="280"/>
      <text>加購</text>
      <text width="2" height="2"/>
      <position x="160" y="280"/>
      <text>${data?.ListOrderAddOn?.filter((add) => add.AddOnType !== 4)
        .map((item) => item.AddOnName)
        .join("/")}</text>
      <text width="1" height="1"/>
      <position x="1" y="340"/>
      <text>支數</text>
      <text width="2" height="2"/>
      <position x="160" y="340"/>
      <text>${index + 1}-${filterData.length} </text>
      <text width="1" height="1"/>
      <position x="545" y="40"/>
      <text>交拍</text>
      <text width="2" height="2"/>
      <position x="705" y="40"/>
      <text>${data?.DeliveryDate}</text>
      <text width="1" height="1"/>
      <position x="545" y="100"/>
      <text>收拍</text>
      <position x="705" y="100"/>
      <text>${data?.ReceivedDate}</text>
      <position x="545" y="160"/>
      <text>備註</text>
      <text width="2" height="2"/>
      <position x="705" y="160"/>
      <text>${data?.Remark}</text>
      <text width="1" height="1"/>
      <position x="545" y="280"/>
      <text>金額</text>
      <position x="705" y="280"/>
      <text>總額 ${data?.Cost} 已付 ${data?.Deposit}</text>
      <position x="545" y="340"/>
      <text>應收</text>
      <text width="2" height="2"/>
      <position x="705" y="340"/>
      <text>${data?.AmountPayable}</text>
      <feed/>
      </page>
      <cut type="feed"/>
      `;
      return req;
    });

    print[0](
      startEnvelope + printData.toString().split(",").join("") + endEnvelope
    );

    // 恢復列印狀態
    setPrintStatus(false);
  };

  useEffect(() => {
    if (isSuccess) {
      MySwal.fire({
        title: "建立成功",
        icon: "success",
        confirmButtonText: "確定",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setOrderList([]);
        }
      });

      if (printStatus) {
        const params = {
          ReceivedDate: formatDate(new Date()),
        };
        _getRecordData(params);
        console.log("列印訂單");
      }
    }

    if (isError) {
      MySwal.fire({
        title: error?.data?.Message,
        icon: "error",
        confirmButtonText: "確定",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [data, isError]);

  // useEffect(() => {
  //   if (grommetID) {
  //     const grommentCost = grommetData?.[0]?.Options?.filter(
  //       (data) => data.Value == grommetID
  //     )?.[0]?.Others?.Cost;
  //     setGrommetCost(grommentCost);
  //   }
  // }, [grommetID]);

  // useEffect(() => {
  //   setCost(grommentCost + stringCost);
  // }, [grommentCost, stringCost]);

  const header = [
    // {
    //   id: 1,
    //   name: "編號",
    //   value: "id",
    //   width: "10",
    // },
    {
      id: 2,
      name: "客戶姓名",
      value: "CustomerName",
      width: "10",
    },
    {
      id: 3,
      name: "電話",
      value: "CustomerID",
      width: "10",
    },
    {
      id: 4,
      name: "線種",
      value: "StringID",
      width: "10",
    },
    {
      id: 5,
      name: "顏色",
      value: "Colour",
      width: "10",
    },
    {
      id: 6,
      name: "磅數",
      value: "Lbs",
      width: "10",
    },
    {
      id: 7,
      name: "穿線法",
      value: "Method",
      width: "10",
    },
    {
      id: 8,
      name: "收拍日",
      value: "ReceivedDate",
      width: "10",
    },
    {
      id: 9,
      name: "交拍日",
      value: "DeliveryDate",
      width: "10",
    },
    {
      id: 10,
      name: "經手人",
      value: "Adjudicator",
      width: "10",
    },
    {
      id: 11,
      name: "穿線付款",
      value: "PayStatus",
      width: "10",
    },
    {
      id: 12,
      name: "新拍",
      value: "IsNew",
      width: "10",
    },
    // {
    //   id: 13,
    //   name: "已付金額",
    //   value: "Deposit",
    //   width: "10",
    // },
    // {
    //   id: 14,
    //   name: "應付金額",
    //   value: "AmountPayable",
    //   width: "10",
    // },
    {
      id: 15,
      name: "加購項目",
      value: "ListOrderAddOn",
      width: "10",
    },

    // {
    //   id: 12,
    //   name: "操作",
    //   value: "Action",
    //   width: "10",
    // },
  ];

  //姓名、電話、交拍日、經手人

  useEffect(() => {
    if (orderList.length > 0) {
      setTempData([orderList[0]]);
      // 如果當下已經有一筆資料 即使點擊Menu的訂單建立
      // 也不要清空燈箱內容
      custData[1](false);
    } else {
      setTempData([]);
    }
  }, [orderList]);

  const handleCopy = (id) => {
    const copyData = orderList.filter((data) => data.id === id)[0];

    setOrderList((orderList) => [
      ...orderList,
      { ...copyData, id: orderList.length + 1, isOpen: false },
    ]);
  };

  const handleEdit = (id) => {
    agreement[2](true);
    setModalType("edit");
    setEditData(orderList.filter((data) => data.id === id));
  };

  const handleFunctionOpen = (id) => {
    setOrderList(
      orderList.map((data) => {
        if (data.id == id) {
          return {
            ...data,
            isOpen: !data.isOpen,
          };
        } else {
          return {
            ...data,
            isOpen: false,
          };
        }
      })
    );
  };

  const handleCreateModal = () => {
    agreement[2](true);
    setModalType("create");
    setEditData([]);
  };

  // const handleAgreement = () => {
  //   MySwal.fire({
  //     title: "同意聲明",
  //     icon: "warning",
  //     html: `
  //     <ul>
  //         <li style="text-align:left">本店不提供寄放拍袋，絨布袋服務</li>
  //         <li style="text-align:left">
  //           無烤漆，毛拍，超過建議磅數，拍札受損，掉漆之球拍，穿線斷裂不負責
  //         </li>
  //         <li style="text-align:left">
  //           本店不會故意使拍子烤漆受損，亦不負責所有拍子烤漆塗裝之問題，如很在意請三思再進行穿線動作，所有烤漆問題請自行處理
  //         </li>
  //         <li style="text-align:left">取拍時請當面詳細檢查，離開後不負任何責任</li>
  //         <li style="text-align:left">當日急件加300元急件費用，普通急件200元</li>
  //         <li style="text-align:left">穿線一律現金付款</li>
  //         <li style="text-align:left">非約定日期提前取拍，一律算急件費用</li>
  //         <li style="text-align:left">同意於本店穿線，即代表同意所有規定及事項</li>
  //       </ul>
  //   `,
  //     confirmButtonText: "同意",
  //     confirmButtonColor: "#16161a",
  //     showCancelButton: true,
  //     cancelButtonText: "取消",
  //     cancelButtonColor: "#ccc",
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setIsOpen(true);
  //     }
  //   });
  // };

  const openAgreementModal = () => {
    agreement[0]();
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>訂單建立</Title>
        </TitleWrapper>
        <TableTitleWrapper>
          <TableTitle>訂單列表</TableTitle>
          {orderList && orderList.length > 0 && (
            <CreateOrder onClick={handleCreateModal}>
              <CreateOrderIcon>
                <FontAwesomeIcon icon={faPlus} />
              </CreateOrderIcon>

              <CreateOrderText>新增訂單</CreateOrderText>
            </CreateOrder>
          )}
        </TableTitleWrapper>

        {orderList && orderList.length > 0 ? (
          <TableWrapper>
            {/* <TableHeader>
              {header.map((item) => (
                <TableHeaderItem
                  key={item.id}
                  style={{
                    width: `${item.width}%`,
                  }}
                >
                  {item.name}
                </TableHeaderItem>
              ))}
            </TableHeader> */}
            <TableContainer>
              {orderList &&
                orderList.map((data, index) => (
                  <Order key={index}>
                    <IdWrapper>
                      <Id>編號 {data.id}</Id>
                      {/* <DeleteIcon>
                        <FontAwesomeIcon
                          icon={faEllipsis}
                          onClick={() => handleFunctionOpen(data.id)}
                        />
                      </DeleteIcon> */}
                      <FunctionWrapper $status={data.isOpen}>
                        <FunctionContainer>
                          <FunctionList onClick={() => handleCopy(data.id)}>
                            <FunctionIcon>
                              <FontAwesomeIcon icon={faCopy} />
                            </FunctionIcon>
                            <FunctionText>複製</FunctionText>
                          </FunctionList>
                          <FunctionList onClick={() => handleEdit(data.id)}>
                            <FunctionIcon>
                              <FontAwesomeIcon icon={faPencil} />
                            </FunctionIcon>
                            <FunctionText>編輯</FunctionText>
                          </FunctionList>
                          <FunctionList onClick={() => handleDelete(data.id)}>
                            <FunctionIcon>
                              <FontAwesomeIcon icon={faTrash} />
                            </FunctionIcon>
                            <FunctionText>刪除</FunctionText>
                          </FunctionList>
                        </FunctionContainer>
                      </FunctionWrapper>
                    </IdWrapper>
                    <OrderContainer key={index}>
                      {header.map((item) => (
                        <OrderItemWrapper key={item.id}>
                          <OrderItemTitle>{item.name}</OrderItemTitle>
                          <OrderItem
                          //   style={{
                          //     width: `${item.width}%`,
                          //   }}
                          >
                            {(() => {
                              switch (item.value) {
                                case "Action":
                                //   return (
                                //     <FontAwesomeIcon
                                //       icon={faTrash}
                                //       onClick={() => handleDelete(data.id)}
                                //     />
                                //   );

                                case "StringID":
                                  return stringData?.filter(
                                    (string) =>
                                      string.StringID == data[item.value]
                                  )?.[0]?.StringOfficialName;

                                case "Colour":
                                  return typeof data.Colour === "string"
                                    ? data.Colour
                                    : colourData?.[0]?.Options?.filter(
                                        (color) => color.Value == data.Colour
                                      )?.[0]?.Text;

                                case "Method":
                                  return methodData?.[0]?.Options?.filter(
                                    (method) => method.Value == data[item.value]
                                  )?.[0]?.Text;

                                case "Adjudicator":
                                  return staffData?.[0]?.Options?.filter(
                                    (staff) => staff.Value == data[item.value]
                                  )?.[0]?.Text;

                                case "IsNew":
                                  return data?.IsNew == 0 ? "舊拍" : "新拍";

                                case "PayStatus":
                                  return data?.PayStatus == 0
                                    ? data?.IsNew == 1
                                      ? "-"
                                      : "尚未付款"
                                    : "已付款";

                                case "ListOrderAddOn":
                                  return data.ListOrderAddOn?.filter(
                                    (add) => add.AddOnType !== 4
                                  )
                                    .map((item) => item.AddOnName)
                                    .join(" / ");

                                case "Other":
                                  return data.ListOrderAddOn?.filter(
                                    (add) => add.AddOnType === 4
                                  ).length > 0
                                    ? `${
                                        data.ListOrderAddOn?.filter(
                                          (add) => add.AddOnType === 4
                                        )[0]?.AddOnName
                                      } /
                                        ${
                                          data.ListOrderAddOn?.filter(
                                            (add) => add.AddOnType === 4
                                          )[0]?.AddOnCost
                                        }`
                                    : "";

                                default:
                                  return data[item.value];
                              }
                            })()}
                          </OrderItem>
                        </OrderItemWrapper>
                      ))}
                    </OrderContainer>
                    <OtherWrapper>
                      <OrderItemTitle>自訂</OrderItemTitle>
                      <OrderItem>
                        {data.ListCustomize?.filter(
                          (add) => add.AddOnType === 4
                        ).length > 0
                          ? `${
                              data.ListCustomize?.filter(
                                (add) => add.AddOnType === 4
                              )[0]?.AddOnName
                            } /
                                        ${
                                          data.ListCustomize?.filter(
                                            (add) => add.AddOnType === 4
                                          )[0]?.AddOnCost
                                        }`
                          : ""}
                      </OrderItem>
                    </OtherWrapper>
                    <OtherWrapper>
                      <OrderItemTitle>備註</OrderItemTitle>
                      <OrderItem>{data.Remark}</OrderItem>
                    </OtherWrapper>

                    <OrderCostWrapper>
                      <OrderSimpleWrapper>
                        <OrderItemTitle>總金額</OrderItemTitle>
                        <OrderItem>{data.Cost}</OrderItem>
                      </OrderSimpleWrapper>
                      <OrderSimpleWrapper>
                        <OrderItemTitle>已付金額</OrderItemTitle>
                        <OrderItem>{data.Deposit}</OrderItem>
                      </OrderSimpleWrapper>
                      <OrderSimpleWrapper>
                        <OrderItemTitle>應付金額</OrderItemTitle>
                        <OrderItem>{data.AmountPayable}</OrderItem>
                      </OrderSimpleWrapper>
                    </OrderCostWrapper>
                  </Order>
                ))}
            </TableContainer>
            <ButtonWrapper>
              <Button text={"送出訂單"} onClickFun={handleCreate} />
              <Button
                disabled={connection[0] !== "connected"}
                text={"送出並列印"}
                onClickFun={handleCreateAndPrint}
              />
            </ButtonWrapper>
          </TableWrapper>
        ) : (
          <NoData>
            <NoDataIcon>
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                onClick={openAgreementModal}
              />
            </NoDataIcon>
            <NoDataText>新增訂單</NoDataText>
          </NoData>
        )}

        {/* <OrderWrapper>
          <SubTitle>訂單資訊</SubTitle>
          <FormWrapper>
            <Input
              title={"姓名"}
              onChangeFun={setCustomerName}
              value={customerName}
            />
            <Input
              title={"電話"}
              onChangeFun={setCustomerPhone}
              value={customerPhone}
            />
            <SearchInput
              title={"線種"}
              onChangeFun={setStringName}
              value={stringName}
            />
            <Select
              title={"顏色"}
              selectableColor={selectableColor}
              group={"colour"}
              onChangeFun={setColour}
              placeholder={"請先選擇線種"}
            />
            <CombineInput
              title={"磅數"}
              firstValue={firstLbsValue}
              secondValue={secondLbsValue}
              onChangeFirstFun={setFirstLbsValue}
              onChangeSecondFun={setSecondLbsValue}
            />
            <Select
              title={"孔丁"}
              group={"grommet"}
              onChangeFun={setGrommetID}
              value={grommetID}
            />
            <Calendar
              title={"收拍日"}
              onChangeFun={setReceivedDate}
              selectDate={receivedDate}
              minDate={true}
            />
            <Calendar
              title={"交拍日"}
              onChangeFun={setDeliveryDate}
              selectDate={deliveryDate}
              minDate={true}
            />
            <Select title={"經手人"} />
            <Checkbox title={"急件"} onChangeFun={setIsUrgent} />
          </FormWrapper>
          <Textarea title={"備註"} onChangeFun={setRemark} value={remark} />
          <TotalAmountWrapper>
            <TotalAmountContainer>
              <TotalAmountText>總金額</TotalAmountText>
              <TotalAmount>$ {cost}</TotalAmount>
            </TotalAmountContainer>
          </TotalAmountWrapper>
          <ButtonWrapper>
            <Button
              text={"儲存訂單"}
              onClickFun={handleSave}
              disabled={!isValid}
            />
          </ButtonWrapper>
        </OrderWrapper> */}

        {/* <SummaryContainer>
            {orderList.map((data) => (
              <SummaryCard>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>訂單ID</SummaryCardItem>
                  <SummaryCardText> {data.id}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>客戶名稱</SummaryCardItem>
                  <SummaryCardText> {data.CustomerName}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>電話</SummaryCardItem>
                  <SummaryCardText> {data.CustomerPhone}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>線種</SummaryCardItem>
                  <SummaryCardText> {data.StringID}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>顏色</SummaryCardItem>
                  <SummaryCardText> {data.Colour}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>磅數</SummaryCardItem>
                  <SummaryCardText> {data.Lbs}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>孔丁</SummaryCardItem>
                  <SummaryCardText> {data.GrommetID}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>急件</SummaryCardItem>
                  <SummaryCardText> {data.IsUrgent}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>收拍日</SummaryCardItem>
                  <SummaryCardText> {data.ReceivedDate}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>交拍日</SummaryCardItem>
                  <SummaryCardText> {data.DeliveryDate}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>備註</SummaryCardItem>
                  <SummaryCardText> {data.Remark}</SummaryCardText>
                </SummaryCardItemWrapper>
                <SummaryCardItemWrapper>
                  <SummaryCardItem>金額</SummaryCardItem>
                  <SummaryCardText> {data.Cost}</SummaryCardText>
                </SummaryCardItemWrapper>
              </SummaryCard>
            ))}
          </SummaryContainer> */}
        {/* <Button text={"送出訂單"} onClickFun={handleCreate} /> */}

        {/* <NoData>
            <NoDataIcon>
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                onClick={() => setIsOpen(true)}
              />
            </NoDataIcon>
            <NoDataText>新增訂單</NoDataText>
          </NoData> */}
      </Container>

      {agreement[1] && (
        <OrderLayout
          setIsOpen={agreement[2]}
          stringData={stringData}
          orderList={orderList}
          setOrderList={setOrderList}
          editData={editData}
          modalType={modalType}
          setModalType={setModalType}
          tempData={tempData}
          clearData={custData[0]}
        />
      )}
    </Wrapper>
  );
};

export default OrderCreationPage;
