import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Input from "../../global/Input";
import Select from "../../global/Select";
import Calendar from "../../global/Calendar";
import Checkbox from "../../global/Checkbox";
import Textarea from "../../global/Textarea";
import Button from "../../global/Button";
import CombineInput from "../../global/CombineInput";
import SearchInput from "../../global/SearchInput";
import RadioButton from "../../global/RadioButton";
import AddInput from "../../global/AddInput";
import InputSelect from "../../global/InputSelect";

import {
  useGetOrderDetailQuery,
  useUpdateOrderMutation,
} from "../../services/stringingApi";

import { useSelector } from "react-redux";
import { selectDropDown } from "../../slices/systemSlice";

import {
  useGetStringsQuery,
  useGetGrommentQuery,
} from "../../services/systemApi";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { formatDate, mobileFormat, valueCheck } from "../../utils";
import Loading from "../components/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;

  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${RWD_SM(css`
    flex-direction: column;
    align-items: flex-start;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  /* padding: 20px 0; */
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
  text-align: left;
`;

const OrderWrapper = styled.div`
  /* border: 1px solid red; */
`;

const FormWrapper = styled.div`
  padding: 20px 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
  grid-gap: 20px 5vw;

  ${RWD_SM(css`
    grid-gap: 15px 0;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  width: 100%;

  ${RWD_LG(css`
    padding: 50px 0 200px 0;
  `)};
`;

const TotalAmountWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
`;

const TotalAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-top: 20px;
`;

const TotalAmount = styled.div`
  font-size: clamp(20px, 3.125vw, 50px);
  padding-left: 20px;
  font-weight: bold;

  ${RWD_SM(css`
    padding-left: 10px;
  `)};
`;

const TotalAmountText = styled.div`
  /* font-size: clamp(23.5px, 1.041vw, 45px); */
`;

const OrderInfoWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  /* background: #fafafa; */
`;

const OrderInfoContainer = styled.div`
  ${RWD_SM(css`
    display: flex;
  `)};
`;

const OrderInfoItem = styled.div`
  /* padding: 10px; */
  padding: 5px;
  /* color: #b3bfbf; */
  font-size: 13px;

  ${RWD_SM(css`
    margin-right: auto;
  `)};
`;

const AddTitle = styled.div`
  padding: 20px 0;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  color: #003060;
`;

const AddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: #fafafa;
`;

const AddContainer = styled.div`
  display: grid;
  width: 95%;

  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding-top: 20px;
`;

const TextareaWrapper = styled.div`
  /* padding-top: 20px; */
`;

const OrderDetailPage = () => {
  const params = useParams()?.orderId;

  const MySwal = withReactContent(Swal);

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const stringData = useSelector(selectDropDown)?.String;
  const colourData = useSelector(selectDropDown).List?.Colour;

  const [orderData, setOrderData] = useState([]);

  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [stringID, setStringID] = useState("");
  // 顏色  ColourID / ColourName
  const [colourName, setColourName] = useState("");
  const [colourID, setColourID] = useState("");
  const [colour, setColour] = useState("");
  const [selectableColor, setSelectableColor] = useState([]);
  // Lbs
  const [firstLbsValue, setFirstLbsValue] = useState("");
  const [secondLbsValue, setSecondLbsValue] = useState("");

  const [method, setMethod] = useState("");
  const [isNew, setIsNew] = useState(false); // 是否為新拍穿線
  const [deposit, setDeposit] = useState(0); // 訂金
  const [amountPayable, setAmountPayable] = useState(0); // 應付金額
  const [payStatus, setPayStatus] = useState(false);
  const [receivedDate, setReceivedDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [adjudicator, setAdjudicator] = useState("");
  const [cost, setCost] = useState(0);
  const [remark, setRemark] = useState("");

  // 加購區
  // 孔丁 案件 Logo 其他 自訂
  const [grommet, setGrommet] = useState([]);
  const [urgent, setUrgent] = useState([]);
  const [logo, setLogo] = useState([]);
  const [other, setOther] = useState([]);
  const [customize, setCustomize] = useState([]);

  // 線種價錢
  const [stringCost, setStringCost] = useState(0);

  // 表單驗證
  const [checkDataIsValid, setCheckDataIsValid] = useState([]);

  const orderParams = {
    OrderID: params.split("_")[0],
    OrderNo: params.split("_")[1],
  };

  const { data, error, isError, isLoading, isSuccess, refetch, isFetching } =
    useGetOrderDetailQuery(orderParams);

  const [
    _update,
    {
      isSuccess: updateDataIsSuccess,
      data: updateData,
      isError: updateDataIsError,
      error: updateDateError,
    },
  ] = useUpdateOrderMutation();

  useEffect(() => {
    if (updateDataIsSuccess) {
      MySwal.fire({
        title: "編輯成功",
        icon: "success",
        confirmButtonText: "確定",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          refetch();
        }
      });
    }

    if (updateDataIsError) {
      MySwal.fire({
        title: updateDateError?.data?.Message,
        icon: "error",
        confirmButtonText: "確定",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [updateData, updateDataIsError]);

  useEffect(() => {
    if (isSuccess) {
      setOrderData(data?.OrderDetail);
    }

    if (isError) {
      console.log(error.data?.Message);
    }
  }, [data, isError]);

  useEffect(() => {
    setCustomerID(orderData?.CustomerID);
    setCustomerName(orderData?.CustomerName);
    setStringID(orderData?.StringID);
    setColour(orderData?.ColourName ?? orderData?.ColourID);
    setFirstLbsValue(orderData?.Lbs?.split("/")?.[0]);
    setSecondLbsValue(orderData?.Lbs?.split("/")?.[1]);
    setMethod(orderData?.Method);
    setIsNew(orderData?.IsNew);
    setDeposit(orderData?.Deposit);
    setAmountPayable(orderData?.AmountPayable);
    setPayStatus(orderData?.PayStatus ? true : false);
    setReceivedDate(orderData?.ReceivedDate);
    setDeliveryDate(orderData?.DeliveryDate);
    setAdjudicator(orderData?.Adjudicator);
    setCost(orderData?.Cost);
    setRemark(orderData?.Remark);

    setGrommet(
      orderData?.ListOrderAddOn?.filter((data) => data.AddOnType == 1)
    );
    setUrgent(orderData?.ListOrderAddOn?.filter((data) => data.AddOnType == 2));
    setLogo(orderData?.ListOrderAddOn?.filter((data) => data.AddOnType == 3));
    setOther(orderData?.ListOrderAddOn?.filter((data) => data.AddOnType == 5));
    setCustomize(
      orderData?.ListOrderCustomize?.filter((data) => data.AddOnType == 4)
    );
  }, [orderData]);

  // 必填 姓名 電話 線種 磅數 交拍日 （顏色）
  const isValid =
    customerName !== "" &&
    customerID !== "" &&
    stringID !== "" &&
    firstLbsValue !== "" &&
    deliveryDate !== null &&
    deliveryDate !== "";

  const handleUpdate = async () => {
    // 必填 姓名 電話 線種 磅數 交拍日  經手人
    // const isValid =
    //   customerName !== "" &&
    //   customerID !== "" &&
    //   stringID !== "" &&
    //   firstLbsValue !== "" &&
    //   deliveryDate !== null &&
    //   deliveryDate !== "";

    const checkData = await Promise.all([
      valueCheck(customerName, "customerName"),
      valueCheck(customerID, "customerID"),
      valueCheck(stringID, "stringID"),
      valueCheck(firstLbsValue, "firstLbsValue"),
      valueCheck(deliveryDate, "deliveryDate"),
      valueCheck(adjudicator, "adjudicator"),
    ]);

    setCheckDataIsValid(checkData);

    if (checkData.every((data) => data.status === true)) {
      // 未付清 + 新拍 => 訂金必填 ＋ 應付必填
      // 付清 + 新拍 => 訂金必填 ＋ 應付帶 0

      if (!mobileFormat(customerID)) {
        MySwal.fire({
          title: "手機號碼格式錯誤",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else if (isNew && !amountPayable && !deposit) {
        MySwal.fire({
          title: "訂金及應付金額不可皆為零",
          icon: "error",
          confirmButtonText: "確認",
          confirmButtonColor: "#16161a",
        });
      }

      // else if (isNew && payStatus && !deposit) {
      //   MySwal.fire({
      //     title: "訂金不可為空",
      //     icon: "error",
      //     confirmButtonText: "確認",
      //     confirmButtonColor: "#16161a",
      //   });
      // }
      else {
        const data = {
          OrderID: params.split("_")[0],
          OrderNo: params.split("_")[1],
          CustomerID: customerID,
          CustomerName: customerName,
          StringID: stringID,
          ColourName: colourName,
          ColourID: colourID,
          Lbs: secondLbsValue
            ? `${firstLbsValue}/${secondLbsValue}`
            : firstLbsValue,
          Method: method,
          IsNew: isNew ? 1 : 0,
          Deposit: deposit ? Number(deposit) : 0,
          AmountPayable: amountPayable ? Number(amountPayable) : 0,
          PayStatus: payStatus ? 1 : 0,
          ReceivedDate: formatDate(receivedDate),
          DeliveryDate: formatDate(deliveryDate),
          Adjudicator: adjudicator,
          Cost: cost,
          Remark: remark,
          Enabled: 1,
          ListCustomize: customize
            .map((data) => {
              return {
                ...data,
                Enabled: data.Enabled ?? 1,
              };
            })
            .filter(
              (item) =>
                item.hasOwnProperty("OrderAddOnID") ||
                (!item.hasOwnProperty("OrderAddOnID") && item.Enabled)
            ),
          ListOrderAddOn: [...grommet, ...urgent, ...logo, ...other]
            .map((data) => {
              return {
                ...data,
                Enabled: data.Enabled ?? 1,
              };
            })
            .filter(
              (item) =>
                item.hasOwnProperty("OrderAddOnID") ||
                (!item.hasOwnProperty("OrderAddOnID") && item.Enabled)
            ),
        };

        // console.log(data);

        _update(data);
      }
    } else {
      MySwal.fire({
        title: "尚未填妥所有必填欄位",
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            handleScroll();
          }, 300);
        }
      });
    }
  };

  useEffect(() => {
    //  非新拍
    if (!isNew) {
      // 清空cost
      setCost(0);
      let addCost;
      if (grommet && urgent && other && logo && customize) {
        // 加購項目
        addCost = [...grommet, ...urgent, ...logo, ...other, ...customize]
          ?.filter((item) => item.Enabled !== 0)
          ?.map((data) => data.AddOnCost)
          ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        // 線種金額 + 加購項目
        setCost(stringCost + addCost);
        // if (stringCost) {
        //   setCost(stringCost + addCost);
        // }
      }

      if (payStatus) {
        setAmountPayable(0);
        // 已付金額為總金額
        setDeposit(stringCost + addCost);
      } else {
        setAmountPayable(stringCost + addCost);
        // 已付金額為 0
        setDeposit(0);
      }

      // 新拍
    } else {
      // 新拍 => 訂金 + 應付金額 + 免費線錢 + 免費案件費 ＋ 其他加購
      // 穿線付款為false
      setPayStatus(false);
      let isNewAddCost;
      if (grommet && other && logo) {
        isNewAddCost = [...grommet, ...logo, ...other, ...customize]
          ?.filter((item) => item.Enabled !== 0)
          ?.map((data) => data.AddOnCost)
          ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        // 線種金額
        if (stringCost) {
          setAmountPayable(0);
          setDeposit(0);
          setCost(stringCost + isNewAddCost);
        }
      }

      setCost(isNewAddCost + Number(amountPayable) + Number(deposit));

      // setAmountPayable(isNewAddCost + Number(amountPayable));
    }
  }, [
    grommet,
    urgent,
    logo,
    other,
    customize,
    stringCost,
    isNew,
    payStatus,
    deposit,
    amountPayable,
  ]);

  useEffect(() => {
    // Cost 成本價
    // MarkedPrice 標價
    // SellingPrice 售價
    if (stringID) {
      setSelectableColor(
        stringData.filter((data) => data.StringID == stringID)?.[0]
          ?.ListStringColours
      );

      if (isNew) {
        setStringCost(0);
      } else {
        setStringCost(
          stringData.filter((data) => data.StringID === stringID)?.[0]
            ?.SellingPrice
        );
      }
    }
  }, [stringID, isNew]);

  useEffect(() => {
    if (typeof colour === "number") {
      setColourID(colour);
      setColourName("");
    }
    if (typeof colour === "string") {
      setColourName(colour);
      setColourID("");
    }
  }, [colour]);

  return (
    <Wrapper>
      {(isLoading || isFetching) && <Loading />}
      <Container>
        <InfoWrapper>
          <TitleWrapper>
            <Title>
              訂單編號 {orderData?.OrderID}_{orderData?.OrderNo}
            </Title>
          </TitleWrapper>
          <OrderInfoWrapper>
            <OrderInfoContainer>
              <OrderInfoItem>建立時間 {orderData?.CreateDate}</OrderInfoItem>
              <OrderInfoItem>編輯時間 {orderData?.UpdateDate}</OrderInfoItem>
            </OrderInfoContainer>
          </OrderInfoWrapper>
        </InfoWrapper>

        <OrderWrapper>
          <FormWrapper>
            <Input
              title={"姓名"}
              defaultValue={orderData?.CustomerName}
              onChangeFun={setCustomerName}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "customerName"
                )[0]?.status === false
              }
              value={customerName}
            />

            <Input
              title={"電話"}
              defaultValue={orderData?.CustomerID}
              onChangeFun={setCustomerID}
              required={true}
              invalid={
                checkDataIsValid.filter((data) => data.type === "customerID")[0]
                  ?.status === false
              }
              value={customerID}
            />

            <SearchInput
              title={"線種"}
              value={stringID}
              defaultValue={orderData?.StringID}
              onChangeFun={setStringID}
              required={true}
              invalid={
                checkDataIsValid.filter((data) => data.type === "stringID")[0]
                  ?.status === false
              }
            />

            <Input
              title={"顏色"}
              defaultValue={orderData?.ColourName}
              onChangeFun={setColour}
              value={colour}
            />

            {/* <InputSelect
            title={"顏色"}
            selectableColor={selectableColor}
            onChangeFun={setColour}
            value={orderData?.ColourID || orderData?.ColourName}
            placeholder={"請先選擇線種"}
          /> */}

            <CombineInput
              title={"磅數"}
              firstValue={firstLbsValue}
              secondValue={secondLbsValue}
              onChangeFirstFun={setFirstLbsValue}
              onChangeSecondFun={setSecondLbsValue}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "firstLbsValue"
                )[0]?.status === false
              }
            />

            <Select
              title={"穿線法"}
              group={"StringMethod"}
              onChangeFun={setMethod}
              value={orderData?.Method}
            />

            {receivedDate && (
              <Calendar
                title={"收拍日"}
                selectDate={receivedDate}
                onChangeFun={setReceivedDate}
              />
            )}
            {deliveryDate && (
              <Calendar
                title={"交拍日"}
                selectDate={deliveryDate}
                onChangeFun={setDeliveryDate}
                minDate={true}
                required={true}
                invalid={
                  checkDataIsValid.filter(
                    (data) => data.type === "deliveryDate"
                  )[0]?.status === false
                }
              />
            )}

            <Select
              title={"經手人"}
              group={"Staff"}
              onChangeFun={setAdjudicator}
              value={orderData?.Adjudicator}
              required={true}
              invalid={
                checkDataIsValid.filter(
                  (data) => data.type === "adjudicator"
                )[0]?.status === false
              }
            />
          </FormWrapper>

          <TextareaWrapper>
            <Textarea
              title={"備註"}
              onChangeFun={setRemark}
              defaultValue={orderData.Remark}
            />
          </TextareaWrapper>

          <AddTitle>加購區</AddTitle>
          <AddWrapper>
            <AddContainer>
              <RadioButton
                title={"孔丁"}
                group={"Grommet"}
                value={grommet?.[0]?.AddOnID}
                onChangeFun={setGrommet}
                type={"update"}
                addData={grommet}
                // value={
                //   listOrderAddOn?.filter((data) => data.AddOnType == 1)[0]
                //     ?.AddOnID
                // }
              />

              <RadioButton
                title={"案件"}
                group={"Urgent"}
                value={urgent?.[0]?.AddOnID}
                onChangeFun={setUrgent}
                type={"update"}
                addData={urgent}
                disabled={isNew}
                // value={
                //   listOrderAddOn?.filter((data) => data.AddOnType == 2)[0]
                //     ?.AddOnID
                // }
              />
              <RadioButton
                title={"Logo"}
                group={"Logo"}
                value={logo?.[0]?.AddOnID}
                onChangeFun={setLogo}
                type={"update"}
                addData={logo}
                // value={
                //   listOrderAddOn?.filter((data) => data.AddOnType == 3)[0]
                //     ?.AddOnID
                // }
              />
              <RadioButton
                title={"其他"}
                group={"Other"}
                value={other?.[0]?.AddOnID}
                onChangeFun={setOther}
                addData={other}
                type={"update"}
              />
              <AddInput
                title={"自訂"}
                group={"Customize"}
                namePlaceholder={"名稱"}
                costPlaceholder={"價格"}
                addData={customize}
                nameValue={customize?.[0]?.AddOnName}
                costValue={customize?.[0]?.AddOnCost}
                onChangeFun={setCustomize}
                type={"update"}
              />
            </AddContainer>
          </AddWrapper>

          <FormWrapper>
            <Checkbox
              title={"穿線付款"}
              value={orderData?.PayStatus}
              onChangeFun={setPayStatus}
              disabled={isNew}
            />

            <Checkbox
              title={"新拍"}
              value={orderData?.IsNew}
              onChangeFun={setIsNew}
            />
            {/* 新拍顯示訂金 + 應付金額 */}
            {isNew && (
              <Input
                title={"訂金"}
                defaultValue={orderData?.Deposit}
                onChangeFun={setDeposit}
                disabled={!isNew}
                value={deposit}
              />
            )}

            {isNew && (
              <Input
                title={"應付金額"}
                defaultValue={orderData?.AmountPayable}
                onChangeFun={setAmountPayable}
                value={amountPayable}
                disabled={!isNew || (isNew && payStatus)}
              />
            )}
          </FormWrapper>
        </OrderWrapper>

        <TotalAmountWrapper>
          <TotalAmountContainer>
            <TotalAmountText>總金額</TotalAmountText>
            {cost && <TotalAmount>$ {cost}</TotalAmount>}
          </TotalAmountContainer>
          <TotalAmountContainer>
            <TotalAmountText>已付金額</TotalAmountText>
            <TotalAmount>$ {deposit === "" ? 0 : deposit}</TotalAmount>
          </TotalAmountContainer>
          <TotalAmountContainer>
            <TotalAmountText>應付金額</TotalAmountText>
            <TotalAmount>
              $ {amountPayable === "" ? 0 : amountPayable}
            </TotalAmount>
          </TotalAmountContainer>
        </TotalAmountWrapper>
        <ButtonWrapper>
          {/* <Button text={"複製訂單"} />
            {isEdit ? (
              <Button text={"編輯完成"} onClickFun={handleUpdate} />
            ) : (
              <Button onClickFun={handleEditStatus} text={"編輯訂單"} />
            )} */}

          <Button
            onClickFun={handleUpdate}
            text={"更新訂單"}
            // disabled={!isValid}
          />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default OrderDetailPage;
