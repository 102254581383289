import { Navigate } from "react-router-dom";
import { useState } from "react";
import { getAuthToken } from "../utils";
const Protected = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(getAuthToken().token);
  if (!isLoggedIn) {
    return (
      <Navigate
        to={`${window.location.origin}${process.env.REACT_APP_PATH}`}
        replace
      />
    );
  }
  return children;
};
export default Protected;
