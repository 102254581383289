import { styled, css } from "styled-components";
import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faStarOfLife } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
`;

const Title = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  /* color: #003060; */
  color: ${({ $required, $invalid }) =>
    $required ? ($invalid ? "#EF233C" : "#F77F00") : "#003060"};
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border: ${({ $invalid }) =>
    $invalid ? "2px solid #EF233C" : "1px solid transparent"};
`;

const SelectLabelButton = styled.button`
  background-color: white;
  border: none;
  /* height: 3.7vh; */
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  cursor: pointer;
  transition: 0.3s ease;
  width: 100%;
  padding: 10px 20px;
  /* font-size: 0.8vw; */
  display: flex;

  ${(props) =>
    props.active === "invalid"
      ? css`
          color: red;
        `
      : props.active === "passed"
      ? css`
          color: #009a63;
        `
      : css`
          /* color: #b6b6b6; */
        `}
`;

const SelectText = styled.div``;

const ArrowIcon = styled.div`
  display: flex;

  transition: 0.3s ease;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: scaleY(-1);
    `}/* border: 1px solid blue; */
`;

const DropdownStyle = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;

  max-height: 20vmax;
  z-index: 5;
  width: 100%;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

const Item = styled.div`
  padding: 10px 20px;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;

  /* font-size: 0.9375vw; */

  /* ${(props) =>
    props.active &&
    css`
      color: #009a63;
      font-weight: 500;
    `} */
  &:hover,
  :focus,
  :focus:hover {
    background-color: #16161a;
    color: #fafafa;
    outline: none;
  }
`;

const RequiredIcon = styled.div`
  font-size: 5px;
  padding-left: 4px;
`;

const Select = ({
  title,
  onChangeFun,
  group,
  value,
  selectableColor,
  placeholder,
  content,
  defaultValue,
  required,
  invalid,
}) => {
  const data = useSelector(selectDropDown).List;

  const colour = useSelector(selectDropDown).List?.Colour;

  // const [colorData, setColorData] = useState([]);

  // useEffect(() => {
  //   if (selectableColor?.length > 0) {
  //     setColorData(
  //       colour?.[0]?.Options?.filter(
  //         (data) => selectableColor?.indexOf(data.Text) > -1
  //       )
  //     );
  //   } else {
  //     setColorData([]);
  //   }
  // }, [selectableColor]);

  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      setCurrentValue("");
    }
  }, [value]);
  const handleOpen = () => {
    if (group === "colour") {
      if (selectableColor.length > 0) {
        setOpen(!open);
      }
    } else {
      setOpen(!open);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value) => {
    setCurrentValue(value);
  };
  const handleChange = (value) => {
    handleValueChange(value);
    // call method, if it exists
    if (onChangeFun) onChangeFun(value);
    // close, after all tasks are finished
    handleClose();
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Wrapper>
      <Title $required={required} $invalid={invalid && !value}>
        {title}
        {required && (
          <RequiredIcon>
            <FontAwesomeIcon icon={faStarOfLife} />
          </RequiredIcon>
        )}
      </Title>

      <SelectContainer $invalid={invalid && !value}>
        {(() => {
          switch (group) {
            // case "grommet":
            //   return (
            //     <Fragment>
            //       <SelectLabelButton onClick={handleOpen}>
            //         <SelectText>
            //           {grommet.filter(
            //             (data) => data.GrommetID == currentValue
            //           )[0]?.GrommetName || "請選擇"}
            //         </SelectText>

            //         <ArrowIcon $isVisible={open}>
            //           <FontAwesomeIcon icon={faChevronDown} />
            //         </ArrowIcon>
            //       </SelectLabelButton>
            //       <DropdownStyle $isVisible={open}>
            //         {grommet &&
            //           grommet.map((data) => (
            //             <DropdownItem
            //               value={data.GrommetID}
            //               key={data.GrommetID}
            //               onClick={() => handleChange(data.GrommetID)}
            //             >
            //               <Item>{data.GrommetName}</Item>
            //             </DropdownItem>
            //           ))}
            //       </DropdownStyle>
            //     </Fragment>
            //   );
            case "selectColour":
              return (
                <Fragment>
                  <SelectLabelButton onClick={handleOpen}>
                    <SelectText>
                      {selectableColor?.filter(
                        (data) => data.ColourID == currentValue
                      )[0]?.ColourName || "請先選擇線種"}
                    </SelectText>

                    <ArrowIcon $isVisible={open}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </ArrowIcon>
                  </SelectLabelButton>
                  <DropdownStyle $isVisible={open}>
                    {selectableColor &&
                      selectableColor.map((data) => (
                        <DropdownItem
                          value={data?.ColourID}
                          key={data?.ColourID}
                          onClick={() => handleChange(data?.ColourID)}
                        >
                          <Item>{data?.ColourName}</Item>
                        </DropdownItem>
                      ))}
                  </DropdownStyle>
                </Fragment>
              );

            default:
              return (
                <Fragment>
                  <SelectLabelButton onClick={handleOpen}>
                    <SelectText>
                      {data[group]?.[0]?.Options.filter(
                        (data) => data.Value == currentValue
                      )[0]?.Text || placeholder}
                    </SelectText>

                    <ArrowIcon $isVisible={open}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </ArrowIcon>
                  </SelectLabelButton>
                  <DropdownStyle $isVisible={open}>
                    {data &&
                      data[group]?.[0]?.Options.map((data) => (
                        <DropdownItem
                          value={data.Value}
                          key={data.Value}
                          onClick={() => handleChange(data.Value)}
                        >
                          <Item>{data.Text}</Item>
                        </DropdownItem>
                      ))}
                  </DropdownStyle>
                </Fragment>
              );
          }
        })()}
      </SelectContainer>
    </Wrapper>
  );
};

export default Select;
