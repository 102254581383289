import { styled, css } from "styled-components";
import { useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { getAuthFun } from "../../utils";
import Calendar from "../../global/Calendar";
import Input from "../../global/Input";
import Button from "../../global/Button";
import SubButton from "../../global/SubButton";

import { formatDate } from "../../utils";
import { useGetAdminOrderByDateMutation } from "../../services/stringingApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleExclamation,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import Loading from "../components/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 50px;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding-bottom: 150px;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const SearchWrapper = styled.div``;

const SearchContainer = styled.div`
  padding: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px 5vw;

  ${RWD_LG(css`
    padding: 0 0 20px 0;
    grid-gap: 15px 0;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  `)};
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  /* flex: 1; */
`;

const TableEl = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableContainer = styled.tbody`
  /* padding-top: 20px; */
  /* overflow-x: scroll; */
  /* width: 100vw; */
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-left: auto;

  button:first-child {
    margin-right: 15px;
  }
`;

const TableTitle = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  text-align: left;
  padding: 30px 0 20px 0;
`;

const TableHeader = styled.thead`
  /* display: flex; */
  /* width: 100vw; */
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableHeaderTr = styled.tr``;

const TableHeaderItem = styled.th`
  padding: 20px 15px;

  white-space: nowrap;
  /* color: #d6d6d6; */
  background: #fafafa;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const OrderContainer = styled.tr`
  border-bottom: 1px solid #f3f4f6;
  /* display: flex; */
  /* display: flex; */

  cursor: pointer;

  &:hover {
    background-color: #fcfcfc;
  }
`;

const OrderItem = styled.td`
  /* padding: 0 20px; */
  padding: 20px 15px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  white-space: nowrap;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  color: #ccc;
`;

const NoDataIcon = styled.div`
  font-size: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const NoDataText = styled.div`
  padding: 40px 0;
`;

const OrderManagePage = () => {
  const navigate = useNavigate();
  const authData = getAuthFun();
  // 權限判斷
  // 訂單管理 FunctionId 3
  const [haveAuth, setHaveAuth] = useState(
    authData.filter((data) => data.FunctionId === 3)[0].AuthSeq > 0
  );

  useEffect(() => {
    if (!haveAuth) {
      navigate("/NoAuth");
    }
  }, []);

  const [receivedDateStart, setReceivedDateStart] = useState("");
  const [receivedDateEnd, setReceivedDateEnd] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [recordList, setRecordList] = useState([]);

  const [_getAdminOrder, { isSuccess, data, isError, error, isLoading }] =
    useGetAdminOrderByDateMutation();

  useEffect(() => {
    const data = {
      ReceivedDateStart: "",
      ReceivedDateEnd: "",
      CustomerName: "",
      CustomerPhone: "",
    };
    _getAdminOrder(data);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setRecordList(data.ListGetAdminOrderByDate);
    }

    if (isError) {
      console.log(data?.error?.Messgae);
    }
  }, [data, isError]);

  const header = [
    {
      id: 1,
      name: "編號",
      value: "serial",
      width: "20",
    },
    {
      id: 2,
      name: "日期",
      value: "ReceivedDate",
      width: "20",
    },
    {
      id: 3,
      name: "數量",
      value: "Quantity",
      width: "20",
    },
    {
      id: 4,
      name: "金額",
      value: "Amount",
      width: "20",
    },
    {
      id: 5,
      name: "操作",
      value: "action",
      width: "20",
    },
  ];

  const handleClear = () => {
    setReceivedDateEnd("");
    setReceivedDateStart("");
    setCustomerName("");
    setCustomerPhone("");
    const data = {
      ReceivedDateStart: "",
      ReceivedDateEnd: "",
      CustomerName: "",
      CustomerPhone: "",
    };
    // 一定要帶空的物件
    _getAdminOrder(data);
  };

  const handleSearch = () => {
    const data = {
      ReceivedDateStart: receivedDateStart ? formatDate(receivedDateStart) : "",
      ReceivedDateEnd: receivedDateEnd ? formatDate(receivedDateEnd) : "",
      CustomerName: customerName,
      CustomerPhone: customerPhone,
    };

    _getAdminOrder(data);
  };

  const handleNavigate = (orderDate) => {
    const date = orderDate.split("/").join("");
    // navigate(`/dashboard/orderRecord/${date}`);
    navigate(
      `${process.env.REACT_APP_PATH}/dashboard/orderRecord/${date}?name=${customerName}&phone=${customerPhone}`
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>訂單管理</Title>
        </TitleWrapper>
        <SearchWrapper>
          <SearchContainer>
            <Calendar
              title={"開始日期"}
              selectDate={receivedDateStart}
              onChangeFun={setReceivedDateStart}
            />
            <Calendar
              title={"結束日期"}
              selectDate={receivedDateEnd}
              onChangeFun={setReceivedDateEnd}
            />
            <Input
              title={"客戶名稱"}
              value={customerName}
              onChangeFun={setCustomerName}
            />
            <Input
              title={"客戶電話"}
              value={customerPhone}
              onChangeFun={setCustomerPhone}
            />
          </SearchContainer>
        </SearchWrapper>

        <SearchButtonWrapper>
          <SubButton text={"清除"} onClickFun={handleClear} />
          <Button text={"查詢"} onClickFun={handleSearch} />
        </SearchButtonWrapper>

        <TableTitle>訂單列表</TableTitle>
        <TableWrapper>
          {recordList && recordList.length > 0 ? (
            <TableEl>
              <TableHeader>
                <TableHeaderTr>
                  {header.map((item) => (
                    <TableHeaderItem
                      key={item.id}
                      style={{
                        width: `${item.width}%`,
                      }}
                    >
                      {item.name}
                    </TableHeaderItem>
                  ))}
                </TableHeaderTr>
              </TableHeader>
              <TableContainer>
                {recordList.map((data, index) => (
                  <OrderContainer
                    key={index}
                    onClick={() => handleNavigate(data.ReceivedDate)}
                  >
                    {header.map((item) => (
                      <OrderItem
                        key={item.id}
                        style={{
                          width: `${item.width}%`,
                        }}
                      >
                        {(() => {
                          switch (item.value) {
                            case "serial":
                              return `${index + 1}`;
                            case "action":
                              return (
                                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                              );

                            default:
                              return data[item.value];
                          }
                        })()}
                      </OrderItem>
                    ))}
                  </OrderContainer>
                ))}
              </TableContainer>
            </TableEl>
          ) : (
            <NoData>
              <NoDataIcon>
                <FontAwesomeIcon icon={faFileCircleExclamation} />
              </NoDataIcon>
              <NoDataText>查無資料</NoDataText>
            </NoData>
          )}
        </TableWrapper>
      </Container>
    </Wrapper>
  );
};

export default OrderManagePage;
