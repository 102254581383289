import { styled } from "styled-components";

const ButtonEl = styled.button`
  padding: 10px 10px;
  width: 100%;
  min-width: 100px;
  background-color: #fff;
  cursor: ${({ $status }) => ($status ? "default" : "pointer")};
  /* background: #16161a; */

  border: 1px solid #1e2022;
`;

const Text = styled.div`
  font-size: clamp(14px, 0.729vw, 16px);
  transition: all 0.3s;
  color: #1e2022;
`;

const SubButton = ({ text, onClickFun, disabled }) => {
  return (
    <ButtonEl onClick={onClickFun} disabled={disabled}>
      <Text>{text}</Text>
    </ButtonEl>
  );
};

export default SubButton;
