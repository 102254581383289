import { Fragment } from "react";
import { styled } from "styled-components";

const NavigateLayout = () => {
  // window.location.href = "https://fb.goforone.shop";
  window.location.href = "https://www.facebook.com/ekyo0224";
  return <Fragment></Fragment>;
};

export default NavigateLayout;
