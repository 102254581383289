import { styled } from "styled-components";

const Wrapper = styled.div`
  border: 1px solid red;
`;

const CustomerPage = () => {
  return <Wrapper>CustomerPage</Wrapper>;
};

export default CustomerPage;
