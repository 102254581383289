import { useState } from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  /* border: 1px solid blue; */
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 30% auto 30%;
  align-items: center;
`;

const LabelEl = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: ${({ $required, $invalid }) =>
    $required ? ($invalid ? "#EF233C" : "#F77F00") : "#003060"};
`;

const InputEl = styled.input`
  padding: 10px 20px;
  background: #fafafa;
  border: none;
  border: ${({ $invalid }) =>
    $invalid ? "2px solid #EF233C" : "1px solid transparent"};
`;

const RequiredIcon = styled.div`
  font-size: 5px;
  padding-left: 4px;
`;

const CombineInput = ({
  title,
  placeholder,
  onChangeFirstFun,
  onChangeSecondFun,
  defaultValue,
  disabled,
  firstValue,
  secondValue,
  required,
  invalid,
}) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  const handleBlur = (e) => {
    setFocused(false);
  };

  return (
    <Wrapper>
      <Container>
        <LabelEl
          $required={required}
          $invalid={invalid && !focused && !firstValue}
        >
          {title}
          {required && (
            <RequiredIcon>
              <FontAwesomeIcon icon={faStarOfLife} />
            </RequiredIcon>
          )}
        </LabelEl>
        <InputEl
          $invalid={invalid && !focused && !firstValue}
          $required={required}
          value={firstValue || ""}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) =>
            onChangeFirstFun(e.target.value.replace(/[^\d]/g, ""))
          }
          inputMode="numeric"
          disabled={disabled}
        />
        <span>/</span>
        <InputEl
          value={secondValue || ""}
          placeholder={"選填"}
          onChange={(e) =>
            onChangeSecondFun(e.target.value.replace(/[^\d]/g, ""))
          }
          inputMode="numeric"
          disabled={disabled}
        />
      </Container>
    </Wrapper>
  );
};

export default CombineInput;
