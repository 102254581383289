import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "./query/defaultBaseQuery";
import * as qs from "qs";

export const systemApi = createApi({
  reducerPath: "systemApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getDropDownList: builder.mutation({
      query: ({ Type, StringID }) => ({
        url: `System/GetDropDownList`,
        method: "POST",
        body: qs.stringify({
          Type,
          StringID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getStrings: builder.query({
      query: () => ({
        url: `System/GetStrings`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      providesTags: [{ type: "string", id: "STRING" }],
    }),
    getGetStringDetail: builder.mutation({
      query: ({ StringID }) => ({
        url: `System/GetStringDetail`,
        method: "POST",
        body: qs.stringify({
          StringID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      providesTags: [{ type: "string", id: "STRING" }],
    }),
    createString: builder.mutation({
      query: ({
        StringOfficialName,
        StringName,
        Manufacturer,
        Gauge,
        Specification,
        StrokeFeel,
        Description,
        ListColours,
        ListStringPriceRecords,
      }) => ({
        url: `System/StringCreate`,
        method: "POST",
        body: JSON.stringify({
          StringOfficialName,
          StringName,
          Manufacturer,
          Gauge,
          Specification,
          StrokeFeel,
          Description,
          ListColours,
          ListStringPriceRecords,
        }),
        headers: { "content-type": "application/json" },
      }),
      invalidatesTags: [{ type: "string", id: "STRING" }],
    }),
    updateString: builder.mutation({
      query: ({
        StringID,
        StringOfficialName,
        StringName,
        Manufacturer,
        Gauge,
        Specification,
        StrokeFeel,
        Description,
        Enabled,
        ListColours,
        ListStringPriceRecords,
      }) => ({
        url: `System/StringEdit`,
        method: "POST",
        body: JSON.stringify({
          StringID,
          StringOfficialName,
          StringName,
          Manufacturer,
          Gauge,
          Specification,
          StrokeFeel,
          Description,
          Enabled,
          ListColours,
          ListStringPriceRecords,
        }),
        headers: { "content-type": "application/json" },
      }),
      invalidatesTags: [{ type: "string", id: "STRING" }],
    }),

    getStaff: builder.query({
      query: () => ({
        url: `System/GetStaff`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      providesTags: [{ type: "staff", id: "STAFF" }],
    }),
    getStaffDetail: builder.mutation({
      query: ({ UserID }) => ({
        url: `System/GetStaffDetail`,
        method: "POST",
        body: qs.stringify({
          UserID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      providesTags: [{ type: "staff", id: "STAFF" }],
    }),
    createStaff: builder.mutation({
      query: ({
        UserNo,
        UserName,
        NickName,
        Phone,
        Email,
        Password,
        RoleID,
        Remark,
      }) => ({
        url: `System/StaffCreate`,
        method: "POST",
        body: qs.stringify({
          UserNo,
          UserName,
          NickName,
          Phone,
          Email,
          Password,
          RoleID,
          Remark,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      invalidatesTags: [{ type: "staff", id: "STAFF" }],
    }),
    updateStaff: builder.mutation({
      query: ({
        UserNo,
        UserName,
        NickName,
        Phone,
        Email,
        Password,
        NewPassword,
        RoleID,
        Remark,
        Enabled,
        UserID,
      }) => ({
        url: `System/StaffEdit`,
        method: "POST",
        body: qs.stringify({
          UserNo,
          UserName,
          NickName,
          Phone,
          Email,
          Password,
          NewPassword,
          RoleID,
          Remark,
          Enabled,
          UserID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      invalidatesTags: [{ type: "staff", id: "STAFF" }],
    }),
    getParam: builder.mutation({
      query: ({ Type, ID, Name, Cost, Remark }) => ({
        url: `System/GetParam`,
        method: "POST",
        body: qs.stringify({
          Type,
          ID,
          Name,
          Cost,
          Remark,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      providesTags: [{ type: "param", id: "PARAM" }],
    }),
    createParam: builder.mutation({
      query: ({ ListParam }) => ({
        url: `System/ParamCreate`,
        method: "POST",
        body: qs.stringify({
          ListParam,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      invalidatesTags: [{ type: "param", id: "PARAM" }],
    }),
    updateParam: builder.mutation({
      query: ({ Type, ID, Name, Cost, Remark, Enabled }) => ({
        url: `System/ParamEdit`,
        method: "POST",
        body: qs.stringify({
          Type,
          ID,
          Name,
          Cost,
          Remark,
          Enabled,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
      invalidatesTags: [{ type: "param", id: "PARAM" }],
    }),
  }),
});

export const {
  useGetDropDownListMutation,

  useGetStringsQuery,
  useGetGetStringDetailMutation,
  useCreateStringMutation,
  useUpdateStringMutation,

  useGetStaffQuery,
  useGetStaffDetailMutation,
  useCreateStaffMutation,
  useUpdateStaffMutation,

  useGetParamMutation,
  useCreateParamMutation,
  useUpdateParamMutation,
} = systemApi;
