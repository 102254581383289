import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "./query/defaultBaseQuery";
import * as qs from "qs";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ UserNo, Password, CompanyCode }) => ({
        url: `Auth/Login`,
        method: "POST",
        body: qs.stringify({
          UserNo,
          Password,
          CompanyCode,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    logout: builder.mutation({
      query: (credentials) => ({
        url: `Auth/Logout`,
        method: "GET",
        body: credentials,
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = loginApi;
