import { styled, css } from "styled-components";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";
import { useEffect, useState } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  ${RWD_SM(css`
    flex-direction: column;
    align-items: flex-start;
  `)};
`;

const LabelEl = styled.label`
  text-align: left;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: #003060;
  width: 15%;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 10px;
  width: 85%;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
  `)};
`;

const InputEl = styled.input`
  padding: 6px 10px;
  border: 1px solid #ccc;
  /* background: #fafafa; */
  /* border: none; */
`;

const AddInput = ({
  title,
  namePlaceholder,
  costPlaceholder,
  addData,
  onChangeFun,
  nameValue,
  costValue,
  type,
}) => {
  const data = useSelector(selectDropDown).List?.Other;

  const [name, setName] = useState("");
  const [cost, setCost] = useState("");

  const handleInput = (e) => {
    switch (e.target.name) {
      case "name":
        if (type === "update") {
          if (addData.length > 0) {
            return onChangeFun([
              {
                ...addData[0],
                ...{
                  AddOnName: e.target.value,
                  Enabled: e.target.value ? 1 : 0,
                },
              },
            ]);
          } else {
            return onChangeFun([
              {
                AddOnType: 4,
                AddOnID: data?.[0]?.Options?.[0]?.Value,
                AddOnName: e.target.value,
              },
            ]);
          }
        } else {
          return onChangeFun([
            {
              AddOnType: 4,
              AddOnName: e.target.value,
              AddOnID: data?.[0]?.Options?.[0]?.Value,
              AddOnCost: addData?.[0]?.AddOnCost ?? 0,
            },
          ]);
        }

      case "cost":
        if (type === "update") {
          if (addData.length > 0) {
            return onChangeFun([
              {
                ...addData[0],
                ...{
                  AddOnCost: Number(e.target.value),
                  Enabled: Number(e.target.value) ? 1 : 0,
                },
              },
            ]);
          } else {
            return onChangeFun([
              {
                AddOnType: 4,
                AddOnID: data?.[0]?.Options?.[0]?.Value,
                AddOnName: "",
                AddOnCost: Number(e.target.value),
              },
            ]);
          }
        } else {
          return onChangeFun([
            {
              AddOnType: 4,
              AddOnName: addData?.[0]?.AddOnName,
              AddOnID: data?.[0]?.Options?.[0]?.Value,
              AddOnCost: Number(e.target.value),
            },
          ]);

          // return onChangeFun([
          //   {
          //     ...addData[0],
          //     ...{
          //       AddOnType: 4,
          //       AddOnCost: Number(e.target.value),
          //       AddOnID: data?.[0]?.Options?.[0]?.Value,
          //     },
          //   },
          // ]);
          // return onChangeFun([
          //   {
          //     AddOnType: 4,
          //     AddOnID: data?.[0]?.Options?.[0]?.Value,
          //     AddOnName: "",
          //     AddOnCost: Number(e.target.value),
          //   },
          // ]);
        }

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Container>
        <LabelEl>{title}</LabelEl>
        <InputWrapper>
          <InputEl
            name={"name"}
            placeholder={namePlaceholder}
            onChange={(e) => handleInput(e)}
            // defaultValue={
            //   listData?.filter((data) => data.AddOnType === 4)[0]?.AddOnName
            // }
            value={nameValue || ""}
          />
          <InputEl
            name={"cost"}
            placeholder={costPlaceholder}
            onChange={(e) => handleInput(e)}
            // defaultValue={
            //   listData?.filter((data) => data.AddOnType === 4)[0]?.AddOnCost
            // }
            value={costValue || ""}
          />
        </InputWrapper>
      </Container>
    </Wrapper>
  );
};

export default AddInput;
